import React, { useEffect, useState } from "react";
import RenewalStartUp from "../component/skill-renewal/blank-renewal";
import RenewalList from "../component/skill-renewal/renewal-list";
import RevewalProgressList from "../component/skill-renewal/renewal-progress-list";
import { useDispatch, useSelector } from "react-redux";
import { callGetApi, callPostApi } from "../../services/roadmap_api";
import {
  storeAllSkillTests,
  storeReloadRoles,
  storeRunSkills,
  storeSelValues,
  storeSkillData,
  storeTabStatus,
} from "../../store/skillRenewal";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import RelevantSkillTestModal from "../modal/relevant-skill-tests";
import {
  randomDescriptions,
  toMessage,
  uniqueByTargetRoleName,
} from "../utils";
import RelevantSkillValidate from "../modal/relevant-skill-validate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../../api";
import { message, Row, Col } from "antd";

const RenewalSkills = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [testsBackup, setTestsBackup] = useState([]);
  const [loadingFor, setLoadingFor] = useState("");
  const [loading, setLoading] = useState(false);
  const [skillTotalCount, setSkillTotalCount] = useState("0");

  const {
    tabStatus,
    allSkillTests,
    allJobRole,
    selectedVal,
    skillData,
    allCurrentSkills,
    isRunSkill,
    allRolesDes,
    roleReload,
  } = useSelector((state) => state?.skillRenewal);

  const userString = localStorage.getItem("user");
  const roleReloadTests = localStorage.getItem("is_realod_tests");

  const user = userString ? JSON.parse(userString) : null;
  // const user = JSON?.parse(localStorage?.getItem("user"));
  console.log(user, "user");

  const { selectedDomain } = useSelector((state) => state?.domainExpert);
  const getData = JSON?.parse(localStorage.getItem("renewal-data"));

  const [openModal, setOpenModal] = useState({ show: false });
  const [openBeta, setOpenBeta] = useState(false);

  const handleBetaShow = () => {
    setOpenBeta(!openBeta);
  };

  const handleShow = () => {
    setLoading(false);
    dispatch(storeSelValues({ type: "role", value: "" }));
    setOpenModal({ show: !openModal?.show });
    localStorage.removeItem("isMap");
    //  localStorage.removeItem("renewal-data")
  };

  const [assessments, setAssessments] = useState([]);
  const handleSkillTest = (assessment_id, assessment_name) => {
    localStorage.setItem("assessment_id", assessment_id);
    localStorage.setItem("assessment_name", assessment_name);
    navigate("/dashboard/skill-test");
  };
  const fetchAssessments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/api/get-employee-assessments/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${user?.token}`, // Ensure user token is available
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setAssessments(data.assessments);
        message.success("Assessments fetched successfully.");
      } else {
        message.error("Failed to retrieve assessments.");
      }
    } catch (error) {
      message.error(`Error fetching assessments: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessments();
  }, []);

  const getAllTests = async () => {
    setLoadingFor("Getting all User tests...");
    const res = await callGetApi(
      `/api/user/get-skill-renewal-dashboard/?user_id=${
        user?.user_id || "1111"
      }`
    );
    console.log(res, "resresresresres");
    if (res && res !== undefined) {
      setLoadingFor("");
      if (res?.length <= 0) {
        dispatch(storeTabStatus("start"));
      }
      let filteredData = uniqueByTargetRoleName(res);
      dispatch(storeAllSkillTests({ type: "tests", data: filteredData }));
      setTestsBackup(res);
    } else {
      setLoadingFor("");
      toMessage("Something went wrong! try again later", "error");
    }
    await getAllJobRoles();
    setLoadingFor("");
  };

  const getAllJobRoles = async () => {
    const res = await callGetApi(`/api/target-role/names/`);
    if (res && Array.isArray(res)) {
      dispatch(storeAllSkillTests({ type: "allRoles", data: res }));
      const formattedRes = res.map((item) => ({
        label: item?.name || "",
        value: (item?.name || "").toLowerCase(),
        desc: item?.description
          ? item?.description
          : randomDescriptions[
              Math.floor(Math.random() * randomDescriptions?.length)
            ],
      }));

      const existingNames =
        allSkillTests?.map((item) => item?.target_role_name?.toLowerCase()) ||
        [];
      const filteredLabels = formattedRes.filter(
        (item) => !existingNames.includes(item?.value)
      );
      dispatch(storeAllSkillTests({ type: "role", data: filteredLabels }));
    }
  };

  // do not remove need this
  const getCurrentSkills = async (type) => {
    // setLoadingFor("getting all User tests...");
    const res = await callGetApi(
      `/api/user/get-skill-renewal/${user?.user_id || "1111"}`
    );
    console.log(res, "resresresresres");
    if (res && res !== undefined) {
      dispatch(storeAllSkillTests({ type: "current", data: res }));
    }
    if (type && type == "get") {
      getAllSkills("CoreSkills");
    }
    // setLoadingFor("");
  };

  const getCurrentSkillsByJobRole = async (type, val) => {
    // setLoadingFor("getting all User tests...");
    const encodedTargetRoleName = encodeURIComponent(
      val ? val : selectedVal?.role?.label || getData?.target_role_name
    );
    const res = await callGetApi(
      `api/user/skill-proficiency-graph/?target_role_name=${encodedTargetRoleName}&user_id=${
        user?.user_id || "1111"
      }`
    );
    console.log(type, val, res, "resresresresres");
    if (res && res !== undefined) {
      dispatch(storeAllSkillTests({ type: "current", data: res }));
    }
    if (type && type == "get") {
      getAllSkills("CoreSkills", val, res);
    }
    // setLoadingFor("");
  };

  const getTotalSkills = async () => {
    const resSkills = await callGetApi(
      `/api/target-role/skill-count/?target_role_name=${encodeURIComponent(
        selectedVal?.role?.value || "Audit Manager"
      )}`
    );
    if (resSkills) {
      let total =
        Number(resSkills?.skills?.technical_skill_count) +
        Number(resSkills?.skills?.soft_skill_count);
      console.log(total, "total");
      setSkillTotalCount(resSkills, total?.toString());
      return total?.toString();
    }
    return "0";
  };

  const handleProceed = async () => {
    if (!selectedVal?.role?.value) {
      toMessage("Please select your job role", "warning");
      return;
    }

    const existingNames =
      allSkillTests?.map((item) => item?.target_role_name?.toLowerCase()) || [];

    let resPost = true;
    if (!existingNames.includes(selectedVal?.role?.value)) {
      let count = await getTotalSkills();
      resPost = await postjobRoleData(count);
    }
    if (resPost) {
      await getCurrentSkillsByJobRole("get", selectedVal?.role?.value);
      dispatch(storeSelValues({ type: "type", value: "CoreSkills" }));
      dispatch(storeTabStatus("skills"));
      handleShow();
    }
  };

  const handleClickRenewal = async (val) => {
    await getCurrentSkillsByJobRole("get", val);
    dispatch(storeSelValues({ type: "type", value: "CoreSkills" }));
    dispatch(storeTabStatus("skills"));
    let router = `/dashboard/renewal/?skill_name=${val}`;
    navigate(router);
  };

  const handleToRoadmap = (role) => {
    getCurrentSkillsByJobRole("notget", role);
  };

  const postjobRoleData = async (count) => {
    setLoading(true);
    const getDesciption = (val) => {
      if (!allRolesDes || !val)
        return randomDescriptions[
          Math.floor(Math.random() * randomDescriptions?.length)
        ];
      let des = allRolesDes.find(
        (it) => it?.name?.toLowerCase() === val?.toLowerCase()
      );
      return des
        ? des?.description
        : randomDescriptions[
            Math.floor(Math.random() * randomDescriptions?.length)
          ];
    };

    let payload = {
      id: 0,
      user_id: user?.user_id || "1111",
      target_role_name: selectedVal?.role?.label,
      skill_ids: [""],
      is_beta: false,
      is_roadmap: false,
      is_content: false,
      description: getDesciption(selectedVal?.role?.label),
      status: "yet to start",
      roadmap_id: "",
      total_count: count || skillTotalCount || "0",
      reached_count: "0",
    };
    let res = await callPostApi(`/api/user/skill-renewal-dashboard/`, payload);
    if (res && res !== undefined) {
      setLoading(false);
      localStorage.setItem("renewal-data", JSON.stringify(res));
      return true;
    } else {
      toMessage("Something went wrong try agan letter", "error");
      setLoading(false);
      return false;
    }
  };

  const getAllSkills = async (tp, role, sorted) => {
    setLoadingFor("Getting skills...");
    let payload = {
      name: role ? role : selectedVal?.role?.value || getData?.target_role_name,
      type: tp
        ? tp == "TechnicalSkills"
          ? "tsc"
          : "ccs"
        : selectedVal?.type == "TechnicalSkills"
        ? "tsc"
        : "ccs",
    };
    const res = await callPostApi(
      `/api/domain-expert/sector/target-roles/skills/`,
      payload
    );
    // const res = await callGetApi(
    //   `/api/domain-expert/sector/target-roles/skills/?name=${
    //     role ? role : selectedVal?.role?.value || getData?.target_role_name
    //   }&type=${
    //     tp
    //       ? tp == "TechnicalSkills"
    //         ? "tsc"
    //         : "ccs"
    //       : selectedVal?.type == "TechnicalSkills"
    //       ? "tsc"
    //       : "ccs"
    //   }`
    // );
    if (res && res !== undefined) {
      let formatedRes = res?.map((it, i) => ({
        id: i + 1,
        skill_name: it?.name,
        proficiency: it?.proficiency,
      }));

      let SortVal = sorted ? sorted : allCurrentSkills;
      let updatedData = SortVal?.length > 1 ? [...SortVal]?.reverse() : SortVal;
      console.log(res, allCurrentSkills, "formatedRes");
      formatedRes?.map((it, i) => {
        const isSkill = updatedData?.find(
          (it2) =>
            it2?.skill_name?.toLowerCase() === it?.skill_name?.toLowerCase()
        );
        if (isSkill) {
          //  let  skilll = { ...isSkill, action: '' };
          formatedRes[i] = isSkill;
        }
        return it;
      });
      //   console.log(filterdRoles2,"formatedRes filterdRoles2")
      //   const getSkillsName = allCurrentSkills?.map((it) =>
      //     it?.skill_name?.toLowerCase()
      //   );
      //   const filterdRoles = formatedRes?.filter(
      //     (it) => !getSkillsName?.includes(it?.skill_name?.toLowerCase())
      //   );
      //   const finalRoles = allCurrentSkills?.concat(filterdRoles.map((it) => it));

      dispatch(storeSkillData(formatedRes));
    } else {
      toMessage("Something went wrong! try again later", "error");
    }
    setLoadingFor("");
  };

  useEffect(() => {
    if (user?.user_id) {
      if (tabStatus == "list") {
        getAllTests();
        // getCurrentSkillsByJobRole()
      }
      // if (allJobRole?.length === 0) {
      //   getAllJobRoles();
      // }
    }
    if (window) {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [tabStatus]);

  useEffect(() => {
    if (tabStatus == "skills" || isRunSkill) {
      getCurrentSkillsByJobRole("get");
      // getCurrentSkills("get");
      dispatch(storeRunSkills(false));
    }
    getAllTests();
  }, [tabStatus, isRunSkill, roleReload, roleReloadTests]);

  // const skill_name= searchParams.get('skill_name');

  // useEffect(()=>{
  //   if(skill_name){
  //     dispatch(storeSelValues({ type: "type", value: "CoreSkills" }));
  //     dispatch(storeTabStatus("skills"));
  //   }else{
  //     dispatch(storeTabStatus("list"));
  //   }
  // },[skill_name])

  return (
    <>
      {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}

      {tabStatus == "list" ? (
        <>
          <RenewalList
            user={user}
            allSkillTests={allSkillTests}
            testsBackup={testsBackup}
            showModal={handleShow}
            handleClickRenewal={handleClickRenewal}
            handleRoadmap={handleToRoadmap}
            handleBetaShow={handleBetaShow}
            assessments={assessments}
            handleSkillTest={handleSkillTest}
          />
        </>
      ) : tabStatus == "skills" ? (
        <RevewalProgressList
          allSkillTests={allSkillTests}
          skillData={skillData}
          getAllSkills={getAllSkills}
          selectedRole={selectedVal?.role?.label || ""}
          getData={getData}         
        />
      ) : (
        <RenewalStartUp
          showModal={handleShow}
          assessments={assessments}
          handleSkillTest={handleSkillTest}
        />
      )}
      <RelevantSkillTestModal
        allJobRole={allJobRole}
        show={openModal?.show}
        handleClose={handleShow}
        handleOk={handleProceed}
        loading={loading}
        selectedVal={selectedVal?.role}
      />
      <RelevantSkillValidate show={openBeta} handleClose={handleBetaShow} />
    </>
  );
};
export default RenewalSkills;
