import React, { useEffect, useState } from "react";
import { Tabs, Select, Table, Button, Input, message } from "antd";
import Loader from "../../components/loader";
import { callPostApi, callPostFormDataApi } from "../../services/roadmap_api";
import { BASE_URL } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import UploadCv from "../../components/UploadCv";
const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;

const LearnerJobSearch = () => {
  const [error, setError] = useState("");
  const [linkorfile, setLinkorfile] = useState(null);
  const [linkedinUrl, setLindedinUrl] = useState(null);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");
  const [allJobs, setAllJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const dispatch = useDispatch();
  const [selectedPools, setSelectedPools] = useState([]);
  const [talentPools, setTalentPools] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState([]);

  const handleButtonClick = () => {
    setIsVisible(true);
  };

  const current_company = JSON.parse(localStorage.getItem("current_company"));
  const user = JSON.parse(localStorage.getItem("user"));

  const loadAllJobs = async () => {
    try {
      setLoading(true);
      setLoadingFor("Fetching Jobs");

      // First API call to get relevant job suggestions
      // Create a new FormData object
      let formData = new FormData();

      // Append fields to the FormData object
      formData.append('company_id', current_company?.id);
      formData.append('candidate_id', user?.id);
      formData.append('candidate_type', 'employee');
      console.log("formdata", formData);

      // Use the FormData object in your API call
      let response = await callPostFormDataApi(
        `/api/job_candidate_panel/get_relevant_jobs_suggestions`,
        formData
      );

      if (response) {
        // Initialize an array to hold all job descriptions
        let allJobs = [];

        // Iterate over each job suggestion and fetch detailed job info
        for (let job of response) {
          try {
            // Fetch job description details based on jd_id
            let jobDetails = await callPostApi(
              `/api/job_candidate_panel/get_job_description_info?company_id=${current_company?.id}&jd_id=${job.jd_id}`,
              {}
            );

            // Combine the job suggestion with the job details and add to the allJobs array
            if (jobDetails && jobDetails["0"]) {
              jobDetails["0"].score = job.score;
              allJobs.push(jobDetails["0"]); // Push only the detailed job info to the array
            }
          } catch (jobError) {
            console.error(
              `Error fetching job details for jd_id ${job.jd_id}:`,
              jobError
            );
          }
        }

        // Set the compiled job descriptions to the state
        setAllJobs(allJobs);
        console.log("allJobs", allJobs);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTalentPools = async (id) => {
    if (user) {
      const response = await fetch(`${BASE_URL}/api/getTalentPoolsOfCompany/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
        body: JSON.stringify({
          company_id: id,
        }),
      });

      const data = await response.json();
      if (data?.talent_pools?.length > 0) {
        setTalentPools(data.talent_pools);
      }
    }
  };

  useEffect(() => {
    getLinkedinUrl(user?.id);
    loadAllJobs();
    getTalentPools(current_company?.id);
  }, []);

  const getLinkedinUrl = async (user_id) => {
    const response = await fetch(`${BASE_URL}/api/user-linkedin-profile/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${user?.token}`,
      },
    });

    const data = await response.json();
    if (data.success) {
      setLindedinUrl(data.linkedin_url);
    }
  };

  const setUserLinedinProfile = async () => {
    const response = await fetch(`${BASE_URL}/api/user-linkedin-profile/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${user?.token}`,
      },
      body: JSON.stringify({
        linkedin_url: linkedinUrl,
      }),
    });
  };

  const handleEditLink = (job) => () => {
    setSelectedJob(job);
    setActiveKey("2");
  };

  const handleEmployeeEditLink = (emp) => () => {
    setEmployee(emp);
    setActiveKey("3");
  };

  const handlePoolsChange = (pools) => {
    setSelectedPools(pools);
  };

  const handleAddJobProfile = async () => {
    if (!linkorfile && !linkedinUrl) {
      setError("Please provide either a LinkedIn URL or upload a CV.");
      return;
    }

    if (!linkedinUrl) {
      setUserLinedinProfile(linkedinUrl);
    }

    try {
      setLoading(true);
      setLoadingFor("Adding Job Profile");
      let url = "";
      if (linkedinUrl) {
        url = `/api/job_candidate_panel/add_candidate_info?company_id=${current_company?.id}&candidate_id=${user?.id}&candidate_type=employee&linkedin_profile_url=${linkedinUrl}`;
      } else {
        url = `/api/job_candidate_panel/add_candidate_info?company_id=${current_company?.id}&candidate_id=${user?.id}&candidate_type=employee&cv_file=${linkedinUrl}`;
      }
      let response = await callPostApi(url, {});
      if (response) {
        message.success(response.data);
        loadAllJobs();
      }
    } catch (error) {
      console.error("Error adding job:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateLinkedinUrl = (value) => {
    const pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[^\/]+\/?$/;
    return pattern.test(value);
  };

  const handleLinkedinChange = (e) => {
    setLindedinUrl(e.target.value);
  };

  const jobColumns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Linkedin Url",
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search URL"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.linkedin_url.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Active", value: "Y" },
        { text: "Inactive", value: "N" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => (status === "Y" ? "Active" : "Inactive"),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search Industry"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.industry.toLowerCase().includes(value.toLowerCase()),
      render: (industry) => industry || "N/A",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        try {
          const parsedTitle = JSON.parse(record.title);
          return parsedTitle?.main_title
            .toLowerCase()
            .includes(value.toLowerCase());
        } catch (error) {
          return false;
        }
      },
      render: (title) => {
        try {
          const parsedTitle = JSON.parse(title);
          return parsedTitle?.main_title || "N/A";
        } catch (error) {
          return "N/A";
        }
      },
    },
    {
      title: "Employment Type",
      dataIndex: "employment_type",
      key: "employment_type",
      render: (employment_type) => employment_type || "N/A",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search Type"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={clearFilters} style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.employment_type.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Relevant",
      dataIndex: "score",
      key: "score",

      render: (score) => `${Math.round(score * 100)}%` || "N/A",
    },
  ];

  return (
    <>
      {loading && <Loader text={loadingFor} />}
      <div className="col-md-12">
        <div className="chart-title d-flex justify-content-between align-items-center">
          <h3>Search Job</h3>
        </div>
        <div className="job-profile mt-4">
          <div className="row py-12">
            <div className="text-end">
              <Button type="primary" onClick={handleButtonClick}>
                Add Your Profile
              </Button>
            </div>
            {isVisible && (
              <div className="linked-url">
                <Tabs
                  activeKey={activeKey}
                  onChange={(key) => setActiveKey(key)}
                  tabBarExtraContent={
                    <Button type="primary" onClick={handleAddJobProfile}>
                      Add
                    </Button>
                  }
                  items={[
                    {
                      key: "1",
                      label: "LinkedIn URL",
                      children: (
                        <div className="d-flex gap-3">
                          <input
                            type="text"
                            value={linkedinUrl}
                            className="form-control"
                            onChange={handleLinkedinChange}
                            placeholder="https://www.linkedin.com/in/david-6456682b0/oad"
                          />
                        </div>
                      ),
                    },
                    {
                      key: "2",
                      label: "Upload CV",
                      children: (
                        <div className="d-flex gap-3">
                          <UploadCv
                            setLinkorfile={setLinkorfile}
                            setNextDisabled={setNextDisabled}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
          <div className="existing-jobs mt-4">
            <h3>Suggested Jobs</h3>
            <Table
              columns={jobColumns}
              dataSource={allJobs}
              rowKey="id"
              pagination={{
                pageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total) =>
                  `Total ${total} Jobs Found In ${current_company?.name}`,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LearnerJobSearch;
