import React, { useEffect, useState } from "react";
import editIcon from "../assets/svg/edit.svg";
import deleteIcon from "../assets/svg/delete.svg";
import plusIcon from "../assets/svg/plus-icon.svg";
import { Select, Tooltip, message } from "antd";
import { Checkbox } from "antd";
import { BASE_URL } from "../api";
import {
  Button,
  Col,
  // DatePicker,
  Form,
  Input,
  Row,
} from "antd";
import { generateUniqueID, calculateTotalExperience } from "../helper/helper";
import moment from "moment";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  callGetApi,
  callGetIp,
  callPostApi,
  callPutApi,
} from "../services/roadmap_api";
import axios from "axios";
import ResetModalCon from "./modal/resetModal";

import DatePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { useDispatch, useSelector } from "react-redux";
import { removeDuplicateSkills, uniqueSkillName } from "../dashboard/utils";
import { storeGlobalSkillsDes } from "../store/slice";
import { storeSavedSkillData } from "../store/skillRenewal";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const ProfileForm = ({
  setAddformdata,
  setPerviousjobs,
  setAddpervjobsfields,
  addformdata,
  previousjobs,
  addpervjobsfields,
  target,
  loader,
  setLoader,
  uniqueSkills,
  setRoadmapLoader,
  setLoadingFor,
  mentorsData,
  cities,
  setCities,
}) => {
  console.log(addformdata, "addformdata");
  console.log(cities, "cities");

  const dispatch = useDispatch();

  let user = JSON?.parse(localStorage.getItem("user"));
  const renewalDataString = localStorage.getItem("renewal-data");
  const renewData =
    renewalDataString && renewalDataString != undefined
      ? JSON.parse(renewalDataString)
      : null;

  const [interest, setInterest] = useState(
    addformdata?.interest ? addformdata?.interest : []
  );
  const [showresetpopup, setShowresetpopup] = useState(false);
  const [clearform, setClearform] = useState(false);
  const [showaddjob, setShowaddjob] = useState(false);
  const [previousShowJob, setPreviousShowjob] = useState("");
  const [cJob, setCJob] = useState(null);
  const [isDisable, setIsDisable] = useState(false);

  const [selectedCities, setSelectedCities] = useState(
    addformdata?.preferences ? addformdata?.preferences : []
  );

  const toggleEditForm = (id) => {
    setShowaddjob(false);
    setPreviousShowjob((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== id) {
          newState[key] = false;
        }
      });
      newState[id] = !newState[id];
      return newState;
    });
  };

  const [summary, setSummary] = useState(
    addformdata.summary ? addformdata?.summary : ""
  );

  const [skills, setSkills] = useState([]);
  const [mSkillsData, setMSkillsData] = useState([]);
  const [allProfSkills, setAllProfSkills] = useState([]);
  const [experiences, setExperiences] = useState(
    addformdata?.experiences ? addformdata?.experiences : []
  );
  const [disabledate, setDisabledate] = useState(false);
  const [totalExp, setTotalExp] = useState(null);
  const [education, setEducation] = useState(
    addformdata?.education ? addformdata?.education : []
  );
  const [certificates, setCertificates] = useState(
    addformdata?.certificates ? addformdata.certificates?.join(",") : []
  );
  const [editpervjob, setEditpervjob] = useState(false);
  const [editid, setEditid] = useState("");
  const [dissubmitbtn, setDissubmitbtn] = useState(false);
  const [stateRe, setStateRe] = useState(1);
  const initialPerJobData = {
    pervjobtitle: "",
    pervjobenddate: null,
    pervjobstartdate: null,
    pervjobrole: "",
    iscurrentjob: false,
  };
  const [perJobData, setPerJobData] = useState(initialPerJobData);
  const [dateError, setDateError] = useState(false);

  const navigate = useNavigate();

  const handleCityChange = (value) => {
    setSelectedCities(value);
  };

  useEffect(() => {
    setStateRe(Math.floor(Math.random() * 100) + 1);
    setSkills(addformdata.skills ? addformdata.skills : []);
    setExperiences(addformdata.experiences ? addformdata.experiences : []);
    setEducation(addformdata?.education ? addformdata?.education : []);
    setCertificates(addformdata?.certificates ? addformdata.certificates : []);
    setInterest(addformdata?.interest ? addformdata.interest : []);
    setPerJobData(initialPerJobData);
    setPerviousjobs([]);
    let isRun = localStorage.getItem("is_run_skill");
    if (addformdata?.skills && isRun) {
      getSkillRelatedData(addformdata?.skills);
      localStorage.removeItem("is_run_skill");
    }
  }, [addformdata]);

  // getting user current skills (tests skills)
  const { allCurrentSkills } = useSelector((state) => state?.skillRenewal);

  const getSkillRelatedData = async (allSkills) => {
    if (allSkills?.length > 0) {
      const tested =
        allSkills?.map((it) => ({
          skill: it?.skill_name || it || "",
          type:
            it?.skill_type?.toLowerCase() === "coreskills" ||
            it?.skill_type === "ccs"
              ? "ccs"
              : it?.skill_type?.toLowerCase() === "technicalskills" ||
                it?.skill_type === "tsc"
              ? "tsc"
              : "",
          proficiency: it?.current_proficiency || "",
        })) || [];

      const currentPre =
        allCurrentSkills?.map((it) => ({
          skill: it?.skill_name || "",
          type:
            it?.skill_type?.toLowerCase() === "coreskills" ||
            it?.skill_type === "ccs"
              ? "ccs"
              : "tsc",
          proficiency: it?.current_proficiency || "",
        })) || [];

      let merged = [...currentPre, ...tested, ...allProfSkills];
      let data = uniqueSkillName(merged);
      setAllProfSkills(data);
    }
  };

  const getRequiredSkillRelatedData = async (allParsedData) => {
    console.log(allParsedData, "updatedSkillsArray allParsedData");

    const response = await callPostApi(
      `/api/llm/analyze_proficiency`,
      allParsedData
    );

    if (response) {
      // const realSkills = allProfSkills?.filter((it) => it?.proficiency != "");
      const responseSkills = response?.map((it) => {
        return {
          proficiency: it?.current_proficiency || "",
          skill: it?.name,
          type: it?.type,
          description: it?.description,
        };
      });

      // let merged = [...realSkills, ...responseSkills];
      let data = uniqueSkillName(responseSkills);
      const desSkills = data?.map((it) => ({ ...it, name: it?.skill }));
      dispatch(storeGlobalSkillsDes(desSkills));
      const updatedSkillsArray = data.map(({ description, ...rest }) => rest);
      console.log(updatedSkillsArray, "updatedSkillsArray allProfSkills");
      return updatedSkillsArray;
    }
  };

  /* */ useEffect(() => {
    if (addformdata.skills) {
      form.setFieldsValue({
        skills: addformdata.skills,
      });
    } else {
      form.setFieldsValue({
        skills: [],
      });
    }
    if (addformdata.summary) {
      form.setFieldsValue({
        summary: addformdata.summary,
      });
    } else {
      form.setFieldsValue({
        summary: "",
      });
    }
    if (addformdata.certificates) {
      form.setFieldsValue({
        CertificationsandAchievements: addformdata.certificates,
      });
    } else {
      form.setFieldsValue({
        CertificationsandAchievements: [],
      });
    }
    if (addformdata.education) {
      form.setFieldsValue({
        education: addformdata.education,
      });
    } else {
      form.setFieldsValue({
        education: [],
      });
    }
  }, [addformdata, experiences]);

  function convertToDateObject(date) {
    if (date instanceof Date) return date;
    if (typeof date === "object" && date?.day && date?.month && date?.year) {
      return new Date(date?.year, date?.month - 1, date?.day);
    } else {
      return Date.now();
    }
  }

  useEffect(() => {
    if (summary.length <= 0 || skills.length <= 0) {
      setDissubmitbtn(true);
    } else {
      setDissubmitbtn(false);
    }
  }, [summary, skills]);

  /*   |     Previous job states       |    */
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const [form] = Form.useForm();
  useEffect(() => {
    if (clearform) {
      form.setFieldsValue({
        summary: null,
        education: [],
        skills: [],
        Addjobtitle: "",
        certificates: [],
        interest: [],
        preferences: [],
      });
      setPerviousjobs([]);
      setSkills([]);
      setInterest([]);
      console.log("interest", interest);
      setAddformdata([]);
      setSummary("");
      setTotalExp("");
      setSelectedCities([]);
      window.setTimeout(() => {
        setStateRe(Math.floor(Math.random() * 100) + 1);
      }, 200);
      console.log("selected cities", selectedCities);
      setClearform(false);
      clearProfile();
    }
  }, [clearform]);

  const addmore = () => {
    setPreviousShowjob({});
    setShowaddjob(!showaddjob);
    setEditpervjob(false);
    setEditid("");
    form.setFieldsValue({
      Addjobtitle: "",
      Startdate: null,
      Enddate: null,
      role: "",
      // Set other fields as needed
    });
  };

  const calculateTime = (ele) => {
    const startDate = new Date(
      ele?.starts_at.year,
      ele?.starts_at.month - 1,
      ele?.starts_at.day
    ); // Month is 0-indexed
    const endDate = new Date(
      ele?.ends_at.year,
      ele?.ends_at.month - 1,
      ele?.ends_at.day
    );

    // Calculate the difference in milliseconds
    const differenceMs = endDate - startDate;

    // Convert milliseconds to years and months
    const differenceYears = Math.floor(
      differenceMs / (1000 * 60 * 60 * 24 * 365.25)
    ); // Average year length
    const differenceMonths = Math.floor(
      (differenceMs % (1000 * 60 * 60 * 24 * 365.25)) /
        (1000 * 60 * 60 * 24 * (365.25 / 12))
    ); // Average month length

    const [years, months] = totalExp.match(/\d+/g).map(Number);

    // Calculate the total number of months
    const totalMonths = years * 12 + months;
    // Form the total time string
    const totalTime = `${differenceYears} years and ${differenceMonths} months`;

    console.log(totalTime); // Output the total time
  };

  const removeperjob = (event, ele) => {
    // const getDate=calculateTime(ele);
    console.log(event, ele, totalExp, "event, id");
    // setTotalExp
    event.preventDefault && event.preventDefault();
    setPerviousjobs(
      (prevjobs) => prevjobs.filter((job) => job.id !== ele?.id) // Filter out the job with the matching id
    );
    setPreviousShowjob("");
    setPerJobData(initialPerJobData);
    setShowaddjob(false);
    setDateError(false);
  };

  const editperjob = (event, id, element) => {
    event?.preventDefault && event?.preventDefault();
    toggleEditForm(id);
    setEditid(id);

    const parseDate = ({ year, month, day }) => new Date(year, month - 1, day);

    const endDate = element?.ends_at ? parseDate(element.ends_at) : new Date();
    const stDate = element?.starts_at
      ? parseDate(element.starts_at)
      : new Date();

    const initialPerJobData = {
      id,
      pervjobtitle: element?.pervjobtitle || element?.title,
      pervjobenddate: endDate,
      pervjobstartdate: stDate,
      pervjobrole: element?.pervjobrole || element?.description,
      iscurrentjob: element?.iscurrentjob || false,
    };

    setPerJobData(initialPerJobData);

    form.setFieldsValue({
      Addjobtitle: initialPerJobData.pervjobtitle,
      Startdate: initialPerJobData.pervjobstartdate,
      Enddate: initialPerJobData.pervjobenddate,
      role: initialPerJobData.pervjobrole,
      iscurrentjob: initialPerJobData.iscurrentjob,
    });
  };

  const selecttags = (eve, type) => {
    let event = eve;
    if (event?.length > 0) {
      event = event?.filter((value) => value.trim() !== "");
    }
    if (type === "interest") {
      setInterest([...event]);
    }
    if (type === "achievements") {
      setCertificates([...event]);
    } else if (type === "skills") {
      setSkills(event);

      const isPresentSkill = allProfSkills.map((it) => it.skill);
      const isNot = event.filter((it) => !isPresentSkill.includes(it));

      if (isNot.length === 0) {
        const filteredArr2 = allProfSkills.filter((value) =>
          event.includes(value.skill)
        );
        setAllProfSkills(filteredArr2);
      } else {
        getSkillRelatedData(isNot);
      }
    } else if (type === "education") {
      setEducation([...event]);
    }
  };

  console.log(cJob, "cJob allCurrentSkills");

  const fVal = (eve) => {
    if (eve?.length > 0) {
      return eve?.filter((value) => value?.trim() !== "");
    }
    return eve;
  };

  const addpreviousjob = async () => {
    try {
      if (perJobData.iscurrentjob) {
        await form.validateFields(["Addjobtitle", "Startdate"]);
      } else {
        await form.validateFields(["Addjobtitle", "Startdate", "Enddate"]);
      }
      console.log(perJobData?.pervjobtitle, "save pervious job ");

      if (perJobData?.pervjobtitle) {
        const updatedPreviousJob = {
          id: editpervjob ? editid : generateUniqueID(),
          title: perJobData?.pervjobtitle,
          ends_at: getObj(perJobData?.pervjobenddate),
          starts_at: getObj(perJobData?.pervjobstartdate),
          description: perJobData?.pervjobrole,
          iscurrentjob: perJobData?.iscurrentjob || false,
        };

        setPerviousjobs((prevJobs) => {
          // If editing, find the index of the job being edited
          if (editid) {
            const index = prevJobs.findIndex((job) => job.id === editid);
            if (index !== -1) {
              // Create a new array with the updated job at the same index
              const updatedJobs = [...prevJobs];
              updatedJobs[index] = updatedPreviousJob;
              return updatedJobs;
            }
          }

          // If not editing, simply add the new job to the array
          return [...prevJobs, updatedPreviousJob];
        });

        // if (perJobData.iscurrentjob) {
        //   setPerviousjobs((prevJobs) =>
        //     prevJobs.map((job) =>
        //       job.id === perJobData?.id
        //         ? { ...job, iscurrentjob: true }
        //         : { ...job, iscurrentjob: false }
        //     )
        //   );}
        // Reset all states after adding the job
        setPerJobData(initialPerJobData);
        setShowaddjob(false);
        setDateError(false);
      }
    } catch (error) {
      console.log(" error to save pervious job data ");
    }
  };

  useEffect(() => {
    console.log(dateError, "------>> Start date is greater --- >");
  }, [dateError]);

  useEffect(() => {
    let pev = perJobData;
    form.setFieldValue("perJobData", perJobData);
    setPerJobData(pev);
  }, [perJobData, editid]);

  const userinput = (event) => {
    let inputname = event.target.name;
    let inputvalue = event.target.value;
    if (inputname === "totalexprience") {
      console.log(inputvalue, "totalexprience is working");
      setTotalExp(inputvalue);
    } else if (inputname === "jobtitle") {
      //  setPervjobtitle(inputvalue)
      setPerJobData({
        ...perJobData,
        pervjobtitle: inputvalue,
      });
      console.log(perJobData.pervjobtitle, " Job title");
    } else if (inputname === "role") {
      //  setPervjobrole(inputvalue); //pervjobrole
      setPerJobData({
        ...perJobData,
        pervjobrole: inputvalue,
      });
    } else if (inputname === "addsummary") {
      const value = event.target.value;
      if (value.trim().length > 0) {
        setSummary(value);
        console.log(value.trim().length, " fds summary - >");
      }
    }
  };

  const selectDate = (date, when) => {
    if (when === "startdate") {
      setPerJobData({
        ...perJobData,
        pervjobstartdate: date,
      });
    } else if (when === "enddate") {
      //  setPervjobenddate(date);
      setPerJobData({
        ...perJobData,
        pervjobenddate: date,
      });
    }
  };

  const handleResetClick = () => {
    setShowresetpopup(true);
  };

  // const handleCheckboxChange = (isChecked) => {
  //   // setIscurrentjob(isChecked);
  //   if (isChecked) {
  //     setDisabledate(true);
  //     const today = new Date();
  //     const dayJsObject = {
  //       $L: "en",
  //       $u: undefined,
  //       $d: today,
  //       $y: today.getFullYear(),
  //       $M: today.getMonth(),
  //       $W: undefined,
  //       $H: today.getHours(),
  //       $m: today.getMinutes(),
  //       $s: today.getSeconds(),
  //       $ms: today.getMilliseconds(),
  //       $isDayjsObject: true,
  //       $x: {}, // Extra properties (if any)
  //     };
  //     // setPerJobData({
  //     //   ...perJobData,
  //     //   iscurrentjob: isChecked
  //     // })
  //     setPerJobData({
  //       ...perJobData,
  //       iscurrentjob: isChecked,
  //       pervjobenddate: isChecked ? dayJsObject : null,
  //     });
  //   } else {
  //     setPerJobData({
  //       ...perJobData,
  //       iscurrentjob: isChecked,
  //       pervjobenddate: null,
  //     });
  //     setDisabledate(false);
  //   }
  // };

  const convertNaNToNull = (date) => {
    if (isNaN(date?.year) || isNaN(date?.month) || isNaN(date?.day)) {
      return null;
    }
    return date;
  };

  useEffect(() => {
    if (experiences?.length > 0) {
      let updatedData = experiences?.map((it) => ({
        ...it,
        iscurrentjob: it.iscurrentjob || !it.ends_at ? true : false,
        starts_at: convertNaNToNull(it.starts_at),
        ends_at: convertNaNToNull(it.ends_at),
      }));
      setPerviousjobs(updatedData);
    }
  }, [experiences]);

  const getTotalMonths = (array) => {
    console.log(array, ` ,ASDHKASGDAS array`);
    let totalMonths = 0;
    const currentDate = new Date(); // Get current date
    if (array?.length > 0) {
      array.forEach((item) => {
        const startDate = item?.starts_at
          ? new Date(
              item?.starts_at?.year,
              item?.starts_at?.month - 1,
              item?.starts_at?.day
            )
          : currentDate;
        const endDate = item?.ends_at
          ? new Date(
              item?.ends_at?.year,
              item?.ends_at?.month - 1,
              item?.ends_at?.day
            )
          : currentDate;

        const monthsDifference =
          (endDate.getFullYear() - startDate.getFullYear()) * 12 +
          (endDate.getMonth() - startDate.getMonth());

        totalMonths += monthsDifference;
      });
    }

    return totalMonths;
  };

  console.log(previousjobs, `,ASDHKASGDAS previousjobs `);
  useEffect(() => {
    if (previousjobs?.length > 0) {
      // console.log(previousjobs, `years ASDHKASGDAS months`);
      let exp = getTotalMonths(previousjobs);

      let totalYears = Math.floor(exp / 12);
      let remainingMonths = exp % 12;

      totalYears = isNaN(totalYears) ? 0 : totalYears;
      remainingMonths = isNaN(remainingMonths) ? 0 : remainingMonths;

      setTotalExp(`${totalYears} years and ${remainingMonths} months`);
    }
  }, [previousjobs]);

  useEffect(() => {
    form.setFieldValue("totalexprience", totalExp);
  }, [totalExp]);

  const getCuurrentJob = () => {
    const current = previousjobs?.filter((it) => it?.ends_at === null);
    return current[0]?.title || "";
  };
  const getPreviousJob = () => {
    const titles = previousjobs.map((job) => job?.title);
    return titles.filter(Boolean);
  };

  const filteredSkills = (skills) => {
    if (Array.isArray(skills)) {
      if (
        Array.isArray(skills) &&
        skills.length > 0 &&
        typeof skills[0] === "object" &&
        "soft_skills" in skills[0] &&
        "technical_skills" in skills[0]
      ) {
        let coreSkills = skills[0]?.soft_skills?.map((it) => ({
          skill: it?.name || "Communication",
          proficiency: it?.proficiency || "basic",
          type: it?.type || "ccs",
          description: it?.description || "",
        }));
        let tecSkills = skills[0]?.technical_skills?.map((it) => ({
          skill: it?.name || "Next js",
          proficiency: +it?.proficiency || 2,
          type: it?.type || "tcs",
          description: it?.description || "",
        }));

        return coreSkills?.concat(tecSkills) || [];
      } else if (
        Array.isArray(skills) &&
        skills?.every((item) => typeof item === "object" && "name" in item)
      ) {
        let updatedkills = skills?.map((it) => ({
          skill: it?.name || "Communication",
          proficiency: it?.proficiency || "Basic",
          type: it?.type || "ccs",
          description: it?.description || "",
        }));
        return updatedkills;
      } else {
        return [
          {
            skill: "Communication",
            proficiency: "Basic",
            type: "ccs",
            description: it?.description || "",
          },
        ];
      }
    } else {
      let coreSkills = skills?.soft_skills?.map((it) => ({
        skill: it?.name || "Communication",
        proficiency: it?.proficiency || "basic",
        type: it?.type || "ccs",
        description: it?.description || "",
      }));
      let tecSkills = skills?.technical_skills?.map((it) => ({
        skill: it?.name || "Nextjs",
        proficiency: +it?.proficiency || 2,
        type: it?.type || "tcs",
        description: it?.description || "",
      }));
      return coreSkills?.concat(tecSkills) || [];
    }
  };

  const clearProfile = async () => {
    try {
      setLoadingFor("Resetting Profile...");
      setLoader(true);
      fetch(`${BASE_URL}/api/clear-talent-profile/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
      })
        .then((response) => {
          setLoadingFor("");
          setLoader(false);
          return response.json().then((data) => {
            console.log("response", data);
            if (response.ok) {
              if (data.success) {
                message.success(data.message);
              } else {
                message.error(data.message);
              }
            } else {
              message.error(data.message || "An error occurred");
            }
          });
        })
        .catch((error) => {
          setLoadingFor("");
          setLoader(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setLoadingFor("");
      setLoader(false);
      console.error("Error generating roadmap:", error);
      // Handle error, such as displaying an error message
    }
  };

  const submitform = async () => {
    try {
      setLoadingFor("Saving Profile...");
      setLoader(true);
      // try {
      let payload = {
        summary,
        interest,
        existing_skills:
          allProfSkills?.length > 0
            ? allProfSkills?.map((it) => it?.skill)
            : [""],
        total_experience: totalExp || "0 years and 0 month",
        education,
        certificates:
          certificates?.length > 0
            ? certificates?.filter((it) => it != null)
            : [],
        previous_job: getPreviousJob(),
        current_job: getCuurrentJob(),
      };

      let requiredStoreSkills = uniqueSkillName(
        filteredSkills(target?.skill?.skills) || []
      );

      let requiredSkills =
        requiredStoreSkills?.map((it) => it?.skill || "Communication") || [];

      fetch(`${BASE_URL}/api/user-talent-profile/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
        body: JSON.stringify({
          summary: summary,
          interest: interest,
          skills: skills,
          education: education,
          certificates: certificates,
          experiences: experiences,
          preferences: selectedCities,
        }),
      })
        .then((response) => {
          setLoadingFor("");
          setLoader(false);
          return response.json().then((data) => {
            console.log("response", data);
            if (response.ok) {
              if (data.success) {
                message.success(data.message);
              } else {
                message.error(data.message);
              }
            } else {
              message.error(data.message || "An error occurred");
            }
          });
        })
        .catch((error) => {
          setLoadingFor("");
          setLoader(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setLoadingFor("");
      setLoader(false);
      console.error("Error generating roadmap:", error);
      // Handle error, such as displaying an error message
    }
  };

  const getProfStatus = (ex, cu, type) => {
    if (!ex || !cu || !type) return false;
    console.log(ex, cu, type, "updatedSkillsArray ex, cu, type");
    if (type === "ccs") {
      const levels = ["basic", "intermediate", "advanced"];

      const exIndex = levels.indexOf(ex?.toLowerCase());
      const cuIndex = levels.indexOf(cu?.toLowerCase());

      if (exIndex === -1 || cuIndex === -1) return false;
      return cuIndex >= exIndex;
    } else {
      const cuIndex = cu?.slice(-1);
      return cuIndex >= ex;
    }
  };

  const postSkillRenewal = async (common) => {
    let payload = common?.map((it, index) => {
      return {
        id: index,
        skill_name: it?.skill ?? "",
        skill_type: it?.type ?? "",
        proficiency: it?.proficiency?.toString() ?? "",
        action: "",
        user_id: user?.user_id ?? "",
        current_proficiency: it?.current_prof?.toString() ?? "",
        asked_question: "",
        current_question: "",
        remaining_time: "",
        status:
          getProfStatus(
            it?.proficiency?.toString(),
            it?.current_prof?.toString(),
            it?.type
          ) || false,
        level_count: "0",
        target_role_name:
          target?.tar?.label ||
          target?.skill?.Job_Role ||
          renewData?.target_role_name ||
          "",
      };
    });

    const res = await callPostApi(`/api/user/skill-renewal/`, payload);

    if (res && res?.length > 0) {
      let ids = res?.map((it) => it?.id?.toString());
      const res2 = await callPutApi(
        `/api/user/skill-renewal-dashboard/${renewData?.id || "1"}`,
        { skill_ids: ids }
      );
      console.log(res2, "res skill post 222");
      // let val = { ...target, ...res };
      // localStorage.setItem("mcq_skills", JSON.stringify(val));
      if (res2 && res2 !== undefined) {
        localStorage.setItem("renewal-data", JSON.stringify(res2));
      }
      // dispatch(storeSavedSkillData(res));
    }
  };

  const validateWhitespace = (_, value) => {
    // Custom validation rule to check for whitespace
    if (value && /^\s+$/.test(value)) {
      return Promise.reject(new Error("Input cannot contain only spaces"));
    }
    return Promise.resolve();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    if (window) {
      window?.scrollTo({
        top: 200,
        behavior: "smooth",
      });
    }
    // Handle form validation failure (Error messages will be shown automatically)
  };

  const handleCurrentJobbox = (eve) => {
    if (!eve?.iscurrentjob) {
      setCJob(eve);
    }

    setPerJobData({
      ...perJobData,
      iscurrentjob: !eve?.iscurrentjob,
      pervjobenddate: !eve?.iscurrentjob ? new Date() : eve?.pervjobenddate,
    });
    form.setFieldsValue({
      Enddate: !eve?.iscurrentjob ? new Date() : eve?.pervjobenddate,
      iscurrentjob: !eve?.iscurrentjob,
    });
  };

  const getObj = (date) => {
    let date1 = new Date(date);

    return {
      day: date1.getDate(),
      month: date1.getMonth(),
      year: date1.getFullYear(),
    };
  };

  return (
    <>
      {stateRe && (
        <div className="form-data custom-large">
          <Row>
            <Col span={24}>
              <Form
                {...formItemLayout}
                form={form}
                id="ProfileForm"
                onFinish={submitform}
                onFinishFailed={onFinishFailed}
                variant="filled"
              >
                <Form.Item
                  label={
                    <span>
                      Add Summary <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="summary"
                  initialValue={summary}
                  rules={[
                    { required: true, message: "Please input the summary!" },
                    { validator: validateWhitespace },
                  ]}
                >
                  <Input.TextArea
                    onChange={userinput}
                    name="addsummary"
                    value={summary}
                    maxLength={2000000}
                  />
                </Form.Item>
                <div className="remove-arrow">
                  <Form.Item
                    label="Add your interest:"
                    // name="interest"
                    initialValue={fVal(interest)}
                    rules={[
                      { required: true, message: "Please enter your interest" },
                    ]}
                    className="tag-input-form-item"
                  >
                    <Select
                      mode="tags"
                      key={"interest"}
                      id={"interest"}
                      value={fVal(interest)}
                      style={{ width: "100%" }}
                      placeholder="Please enter your interest"
                      onChange={(event) => selecttags(event, "interest")}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="remove-arrow">
                  <Form.Item
                    label={
                      <span>
                        Add your skills <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    // name="skills"
                    // id="skills"
                    initialValue={skills}
                    rules={[
                      { required: true, message: "Please enter your skills!" },
                    ]}
                    className="tag-input-form-item"
                  >
                    <Select
                      mode="tags"
                      key={"skills"}
                      id={"skills"}
                      value={skills}
                      style={{ width: "100%" }}
                      placeholder="Please enter your skills"
                      onChange={(event) => selecttags(event, "skills")}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label={
                    <span>
                      Total experience <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="totalexprience"
                  initialValue={totalExp}
                  rules={[
                    {
                      required: totalExp ? false : true,
                      message: "Total experience is required",
                    },
                  ]}
                >
                  <Input
                    onChange={userinput}
                    name="totalexprience"
                    value={totalExp}
                    placeholder="Enter your total exprience"
                    // disabled
                  />
                </Form.Item>

                {/* Show  Previous jobs in fields  */}
                {previousjobs?.map((element, index) => (
                  <div key={index}>
                    {previousjobs.length < 1 && (
                      <Form.Item
                        label="Previous jobs history"
                        name="perviousjobs"
                        // initialValue ={totalExp}
                        rules={[
                          {
                            required: true,
                            message: "Previous jobs history",
                          },
                        ]}
                      ></Form.Item>
                    )}
                    <Form.Item>
                      <Input
                        value={element?.pervjobtitle || element.title}
                        placeholder="Enter your previous job"
                      />
                      <div
                        className="icons-bg"
                        onClick={(event) =>
                          editperjob(event, element.id, element)
                        }
                      >
                        <img src={editIcon} className="edit_icon" />
                      </div>
                      <div
                        className="icons-bg"
                        onClick={(event) => removeperjob(event, element)}
                      >
                        <img src={deleteIcon} className="delete_icon" />
                      </div>
                    </Form.Item>

                    {previousShowJob[element.id] && (
                      <div
                        key={`job-details-${element.id}`}
                        className="form-box-2"
                      >
                        <Form.Item
                          label="Add job title"
                          name="Addjobtitle"
                          rules={[
                            {
                              required: true,
                              message: "Please add your previous job title",
                            },
                            { validator: validateWhitespace },
                          ]}
                        >
                          <Input
                            name="jobtitle"
                            value={perJobData?.pervjobtitle}
                            onChange={userinput}
                            placeholder="Add job title"
                          />
                        </Form.Item>

                        <div className="date-picker-data">
                          <Form.Item
                            label="Start Date"
                            name="Startdate"
                            rules={[
                              {
                                required: true,
                                message: " Please enter a valid date.",
                              },
                            ]}
                            className="DateNotRight"
                          >
                            {console.log(perJobData, "perJobData")}
                            <DatePicker
                              onChange={(date) => selectDate(date, "startdate")}
                              value={perJobData?.pervjobstartdate || new Date()}
                              format="yyyy-MM-dd" // Set the format to display only month and year
                              calendarIcon={null} // Hide the calendar icon
                              maxDate={dayjs().endOf("day").toDate()} // Set maxDate to end of the current day
                            />
                            {/* <DatePicker
                           value={perJobData?.pervjobstartdate ? dayjs(perJobData?.pervjobstartdate) : null}
                            // value={perJobData?.pervjobstartdate}
                            // disabledDate={(current) =>
                            //   current && current > dayjs().endOf("day")
                            // }
                            onChange={(date) => selectDate(date, "startdate")}
                          /> */}
                            {/* <>
                            {dateError && (
                              <div
                                style={{ display: "flex", flexWrap: "nowrap" }}
                              >
                                <div
                                  id="Startdate_help"
                                  className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-1xg9z9n"
                                  role="alert"
                                >
                                  <div className="ant-form-item-explain-error">
                                    
                                  </div>
                                </div>
                                <div
                                  style={{ width: "0px", height: "24px" }}
                                ></div>
                              </div>
                            )}
                          </> */}
                          </Form.Item>
                          <Form.Item
                            label="End Date"
                            name="Enddate"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a valid date",
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={perJobData.iscurrentjob || false}
                              onChange={(date) => selectDate(date, "enddate")}
                              value={perJobData?.pervjobenddate || new Date()}
                              format="yyyy-MM-dd" // Set the format to display only month and year
                              calendarIcon={null} // Hide the calendar icon
                              minDate={perJobData?.pervjobstartdate}
                              maxDate={dayjs().endOf("day").toDate()} // Set maxDate to end of the current day
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          label="Your Roles and Responsibilities"
                          name="role"
                          rules={[
                            {
                              required: false,
                              // message:
                              //   "Please Write your previous role and responsibilities !",
                            },
                            { validator: validateWhitespace },
                          ]}
                        >
                          <Input.TextArea
                            onChange={userinput}
                            name="role"
                            value={perJobData?.pervjobrole}
                            maxLength={200}
                            placeholder="Input your jobs roles and responsibilities ."
                          />
                        </Form.Item>

                        <div className="add-job-checks">
                          <Checkbox
                            name="iscurrentjob"
                            checked={perJobData.iscurrentjob || false}
                            onChange={() => handleCurrentJobbox(perJobData)}
                          >
                            This is my current job
                          </Checkbox>
                          <div className="add-job-btn">
                            {/* <button type="button" onClick={addmore}>
                          Cancel
                        </button>{" "} */}
                            <button type="button" onClick={addpreviousjob}>
                              Update Job
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                <div className="add-more-job noHover">
                  <div className="add-more-job" onClick={addmore}>
                    <p className="">Add more jobs</p>
                    <img src={plusIcon} className="" alt="plus_icon" />
                  </div>
                  {/* {showaddjob?"ture  ":"false"} */}
                </div>

                {showaddjob && (
                  <div className="form-box-2">
                    <Form.Item
                      label="Add job title"
                      name="Addjobtitle"
                      rules={[
                        {
                          required: true,
                          message: "Please add your previous job title",
                        },
                        { validator: validateWhitespace },
                      ]}
                    >
                      <Input
                        name="jobtitle"
                        value={perJobData?.pervjobtitle}
                        onChange={userinput}
                        placeholder="Add job title"
                      />
                    </Form.Item>

                    <div className="date-picker-data">
                      <Form.Item
                        label="Start Date"
                        name="Startdate"
                        rules={[
                          {
                            required: true,
                            message: " Please enter a valid date.",
                          },
                        ]}
                        className="DateNotRight"
                      >
                        <DatePicker
                          onChange={(date) => selectDate(date, "startdate")}
                          value={perJobData?.pervjobstartdate || null}
                          format="yyyy-MM-dd" // Set the format to display only month and year
                          calendarIcon={null} // Hide the calendar icon
                          maxDate={dayjs().endOf("day").toDate()} // Set maxDate to end of the current day
                        />
                        {/* <DatePicker
                          value={perJobData?.pervjobstartdate}
                          disabledDate={(current) =>
                            current && current > dayjs().endOf("day")
                          }
                          onChange={(date) => selectDate(date, "startdate")}
                        /> */}
                        {/* <>
                          {dateError && (
                            <div
                              style={{ display: "flex", flexWrap: "nowrap" }}
                            >
                              <div
                                id="Startdate_help"
                                className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-1xg9z9n"
                                role="alert"
                              >
                                <div className="ant-form-item-explain-error">
                                  uuuuu
                                </div>
                              </div>
                              <div
                                style={{ width: "0px", height: "24px" }}
                              ></div>
                            </div>
                          )}
                        </> */}
                      </Form.Item>
                      <Form.Item
                        className="DateNotRight"
                        label="End Date"
                        name="Enddate"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a valid date",
                          },
                        ]}
                      >
                        <DatePicker
                          disabled={false}
                          onChange={(date) => selectDate(date, "enddate")}
                          value={perJobData?.pervjobenddate || null}
                          format="yyyy-MM-dd" // Set the format to display only month and year
                          calendarIcon={null} // Hide the calendar icon
                          minDate={perJobData?.pervjobstartdate}
                          maxDate={dayjs().endOf("day").toDate()} // Set maxDate to end of the current day
                        />
                        {/* <DatePicker
                          value={perJobData?.pervjobenddate}
                          disabled={disabledate}
                          disabledDate={(current) =>
                            current && current > dayjs().endOf("day")
                          }
                          onChange={(date) => selectDate(date, "enddate")}
                        /> */}
                      </Form.Item>
                    </div>
                    <Form.Item
                      label="Your Roles and Responsibilities"
                      name="role"
                      rules={[
                        {
                          required: false,
                          // message:
                          //   "Please Write your previous role and responsibilities !",
                        },
                        { validator: validateWhitespace },
                      ]}
                    >
                      <Input.TextArea
                        onChange={userinput}
                        name="role"
                        value={perJobData?.pervjobrole}
                        maxLength={200}
                        placeholder="Input your jobs roles and responsibilities."
                      />
                    </Form.Item>

                    <div className="add-job-checks">
                      <Checkbox
                        checked={perJobData.iscurrentjob}
                        onChange={() => handleCurrentJobbox(perJobData)}
                      >
                        This is my current job
                      </Checkbox>
                      <div className="add-job-btn">
                        <button type="button" onClick={addmore}>
                          Cancel
                        </button>{" "}
                        <button type="button" onClick={addpreviousjob}>
                          Add Job
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* sdsdsdsd */}

                <div className="education_select">
                  <Form.Item
                    label="Add your education"
                    // name="education"
                    initialValue={education.length > 0 ? education : []}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Education!",
                      },
                    ]}
                    className="tag-input-form-item"
                  >
                    <Select
                      mode="tags"
                      key={"education"}
                      id={"education"}
                      value={education}
                      style={{ width: "100%" }}
                      placeholder="Please enter your education"
                      onChange={(event) => selecttags(event, "education")}
                    />
                  </Form.Item>
                </div>

                <div className="education_select">
                  <Form.Item
                    label="Certifications and Achievements"
                    // name="certificates"
                    initialValue={certificates.length > 0 ? certificates : []}
                    rules={[
                      {
                        required: false,
                        // message: "Please enter your Achievements!",
                      },
                    ]}
                    className="tag-input-form-item"
                  >
                    <Select
                      mode="tags"
                      key={"Achievements"}
                      id={"Achievements"}
                      style={{ width: "100%" }}
                      value={certificates}
                      placeholder="Please enter your Achievements"
                      onChange={(event) => selecttags(event, "achievements")}
                    />
                  </Form.Item>
                </div>
                <div className="education_select">
                  <Form.Item
                    label="Preferences"
                    name="preferences"
                    initialValue={selectedCities}
                    rules={[
                      {
                        required: true,
                        message: "Please select your preferences!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select your cities"
                      value={selectedCities}
                      onChange={handleCityChange}
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {cities?.map((city) => (
                        <Option key={city.id} value={city.id}>
                          {city.full_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="reset-next-btn pos-bottom">
                  <Button className="reset_btn" onClick={handleResetClick}>
                    Reset
                  </Button>
                  {
                    <Tooltip
                      title={
                        isDisable
                          ? "Please wait, we are generating skill data."
                          : ""
                      }
                      placement="right"
                      color={"#E0E0F0"}
                      key={"#E0E0F0"}
                    >
                      {" "}
                      <span>
                        <Button
                          id="saveprofileBtn"
                          className="reset_btn submit_btn"
                          htmlType="submit"
                          disabled={isDisable}
                        >
                          Save Profile
                        </Button>
                      </span>
                    </Tooltip>
                  }
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      )}
      <ResetModalCon
        showresetpopup={showresetpopup}
        setShowresetpopup={setShowresetpopup}
        setClearform={setClearform}
      />
    </>
  );
};
export default ProfileForm;
