import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../api";
import { useSelector, useDispatch } from "react-redux";
import { storeLoginStatus } from "../store/slice";
import { useGoogleTranslate } from "../GoogleTranslateProvider"; 

function Header({ isLoggedIn, setIsLoggedIn }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [logo, setLogo] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();
  const [access, setAccess] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const isLoaded = useGoogleTranslate();

  useEffect(() => {
    if (isLoggedIn) {
      const jsonData = localStorage.getItem("user");
      if (jsonData) {
        try {
          const userdata = JSON.parse(jsonData);
          setToken(userdata.token);
          setAccess(userdata.token);
          setCompany(userdata.company);
          setType(userdata.type);
          const intervalId = setInterval(() => {
            // refreshToken(token);
          }, 20 * 60 * 1000);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }
      } else {
        setIsLoggedIn(false);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".navbar") && isMenuOpen) {
        setIsMenuOpen(false);
        setIsDivVisible(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/skillsettings/`);
        if (!response.ok) {
          throw new Error("Failed to fetch settings data");
        }
        const data = await response.json();
        setLogo(data.logo);
      } catch (error) {
        console.error("Error fetching settings data:", error);
      }
    };

    const fetchMenu = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/menu/`);
        if (!response.ok) {
          throw new Error("Failed to fetch menu data");
        }
        const data = await response.json();
        setMenuItems(data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchData();
    fetchMenu();
  }, []);

  const renderMenuItems = (menuItems) => {
    return menuItems.map(
      (menuItem) =>
        (menuItem.slug !== "skill-analyser" || isLoggedIn) && (
          <li
            className={`nav-item ${
              (menuItem.slug === "" && location.pathname === "/") ||
              (menuItem.slug !== "" &&
                (location.pathname === `/${menuItem.slug}` ||
                  location.pathname.includes(`/${menuItem.slug}`)))
                ? "active"
                : ""
            } ${menuItem.submenu.length > 0 ? "has-submenu" : ""}`}
            key={menuItem.id}
          >
            <Link
              to={`/${menuItem.slug}`}
              className="nav-link"
              onClick={handleLinkClick}
            >
              {menuItem.title}
            </Link>
            {menuItem.submenu.length > 0 && (
              <ul className="sub-menu">{renderMenuItems(menuItem.submenu)}</ul>
            )}
          </li>
        )
    );
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsDivVisible(!isDivVisible);
  };

  const handleDivClick = () => {
    setIsMenuOpen(false);
    setIsDivVisible(false);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsDivVisible(false);
  };

  const [defaultLanguage, setDefaultLanguage] = useState("en");

  const handleLanguageChange = (event) => {
    setDefaultLanguage(event.target.value);
  };

  return (
    
    <header className="header col-md-12">
      
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="nav-logo" to={`/`}>
            <img src={logo} className="img-fluid" alt="logo" width="100" />
          </Link>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <Link
              className="mobile-logo text-center"
              to={`/`}
              onClick={handleLinkClick}
            >
              <img src={logo} className="img-fluid" alt="logo" width="150" />
            </Link>

            <ul className="navbar-nav ms-auto me-4 mb-2 mb-lg-0">
              {renderMenuItems(menuItems)}
              <li className="nav-item ">
                <Link
                  className="nav-link"
                  to="https://calendly.com/ravi-skillmotion/demosma"
                  target="_blank"
                >
                  Book a Demo
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex">
            <div className="destop-button">
              <ul className="ms-auto mb-lg-0">
                {isLoggedIn ? (
                  <>
                    <li>
                      {type === "12" ? (
                        <Link
                          className="btn button-dark dashboard"
                          to={`/domain-expert-dashboard/`}
                        >
                          Dashboard
                        </Link>
                      ) : type === "13" ? (
                        <Link
                          className="btn button-dark dashboard"
                          to={`/organization/dashboard`}
                        >
                          Dashboard
                        </Link>
                      ) : type === "2" || type === "5" ? (
                        <a
                          className="btn button-dark dashboard"
                          rel="noopener"
                          href={`${BASE_URL}/auto-login/?access=${token}&company=${company}&type=${type}`}
                        >
                          Dashboard
                        </a>
                      ) : (
                        <Link
                          className="btn button-dark dashboard"
                          to={`/dashboard`}
                        >
                          Dashboard
                        </Link>
                      )}

                      <Link className="btn button-dark" to={`/logout`}>
                        Logout
                      </Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link className="btn button-dark" to={`/login`}>
                      Log in
                    </Link>
                  </li>
                )}
                <li className="language-swichter">
                {isLoaded && <div id="google_translate_element"></div>}
                </li>
              </ul>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {isDivVisible && (
              <div className="background-div" onClick={handleDivClick}></div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
