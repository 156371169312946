import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Input, Button, message } from "antd";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../api";
import Loader from "../../components/loader";

const { Option } = Select;

const TalentPool = () => {
  const { id } = useParams();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("True");
  const [matrix, setMatrix] = useState("Skill Matrix");
  const [allCompanies, setAllCompanies] = useState([]);
  const [allSectors, setAllSectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  let user = JSON?.parse(localStorage.getItem("user"));

  const validateWhitespace = (_, value) => {
    if (value && /^\s+$/.test(value)) {
      return Promise.reject(new Error("Input cannot contain only spaces"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {      
      if (id && allCompanies) {
      getTalentPoolDetails(id);
    }
  }, [id, allCompanies]);

  const getCompanies = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/get-all-companies/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setAllCompanies(data.data);
      } else {
        console.error("Failed to retrieve companies:", data.message);
      }
    } catch (error) {
      console.error("Error fetching companies:", error.message);
    }
  };

  const getTalentPoolDetails = async (poolid) => {
    setLoading(true);
    setLoadingFor("Getting Talent Pool Data");
    try {
      const response = await fetch(`${BASE_URL}/api/talent-pool/${poolid}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        const talentpool = data.data;
        setName(talentpool.name);
        const companyIds = talentpool.companies
          ? talentpool.companies.map((company) => company.id)
          : [];
        setSelectedCompanies(companyIds || []);
        console.log("companyIds", companyIds);
        setSelectedSectors(talentpool.sectors || []);
        setMatrix(talentpool.matrix || "Skill Matrix");
        setStatus(talentpool.status ? "True" : "False");
        form.setFieldsValue({
          name: talentpool.name,
          companies: talentpool.companies,
          sectors: talentpool.sectors,
          matrix: talentpool.matrix,
          status: talentpool.status ? "True" : "False",
        });
      } else {
        console.error("Failed to retrieve Talent Pool:", data.message);
      }
    } catch (error) {
      console.error("Error fetching Talent Pool data:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    if (window) {
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
  };

  const submitForm = async () => {
    setLoading(true);
    setLoadingFor("Saving Talent Pool");
    try {
      const values = await form.validateFields();
      const talentpoolData = {
        name,
        companies: selectedCompanies,
        sectors: selectedSectors,
        matrix,
        status: status === "True",
      };

      const url = id
        ? `${BASE_URL}/api/update-talentpool/${id}/`
        : `${BASE_URL}/api/save-talentpool/`;

      const response = await fetch(url, {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
        body: JSON.stringify(talentpoolData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        message.success("Talent Pool saved successfully");
      } else {
        message.error("Failed to save Talent Pool:" + data.message);
      }
    } catch (error) {
      message.error("Failed to save Talent Pool:" + error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  return (
    <>
      {loading && <Loader text={loadingFor} />}
      <div className="assessment-form">
        <div className="headingbar d-flex justify-content-between">
          <div className="title d-flex justify-content-between align-items-end w-100 mb-4">
            <h2>{id ? "Update Talent Pool" : "Create Talent Pool"}</h2>
          </div>
        </div>
        <Form
          {...formItemLayout}
          form={form}
          id="customform"
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input Talent Pool name!" },
                  { validator: validateWhitespace },
                ]}
              >
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    Companies <span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="companies"
                rules={[
                  { required: true, message: "Please select the companies!" },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  value={selectedCompanies}
                  onChange={setSelectedCompanies}
                  placeholder="Select Companies"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allCompanies.map((company) => (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    Sectors <span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="sectors"
                rules={[
                  { required: true, message: "Please select the sectors!" },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  value={selectedSectors}
                  onChange={setSelectedSectors}
                  placeholder="Select Sectors"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allSectors.map((sector, index) => (
                    <Option key={index} value={sector}>
                      {sector}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="Matrix" name="matrix">
                <Input
                  value={matrix}
                  onChange={(e) => setMatrix(e.target.value)}
                  placeholder="Skill Matrix"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  { required: true, message: "Please select the status!" },
                ]}
              >
                <Select
                  value={status}
                  onChange={setStatus}
                  placeholder="Select Status"
                  style={{ width: "100%" }}
                >
                  <Option value="True">Active</Option>
                  <Option value="False">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ span: 24, offset: 6 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="mt-4"
            >
              {id ? "Update" : "Create"} Talent Pool
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default TalentPool;
