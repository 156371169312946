import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Select,
  Row,
  Col,
  message,
  Table,
  Switch,
  InputNumber,
  Input,
  Checkbox, // Import Checkbox component from Ant Design
} from "antd";
import { BASE_URL } from "../../api";
import Loader from "../../components/loader";

const { Option } = Select;
const { TextArea } = Input;

const UpdateSkillMap = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();
  const [talentPools, setTalentPools] = useState([]);
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedTalentPool, setSelectedTalentPool] = useState("");
  const [skills, setSkills] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  let current_company = JSON?.parse(localStorage.getItem("current_company"));
  const [isAllChecked, setIsAllChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");

  // Add New Skill Button function
  const addNewSkill = () => {
    const newSkill = {
      id: "", // New skill has an empty ID initially
      name: "",
      type: "tsc", // Default to Technical Skill
      proficiency: 1, // Default proficiency
      description: "",
      status: false, // Default status to unchecked
    };
    setSkills((prevSkills) => [...prevSkills, newSkill]);
  };

  const talent_pool_options = talentPools.map((talentPool) => (
    <Option key={talentPool.id} value={talentPool.id}>
      {talentPool.name}
    </Option>
  ));

  const domain_options = domains.map((domain) => (
    <Option key={domain} value={domain}>
      {domain}
    </Option>
  ));

  const job_options = jobs.map((job) => (
    <Option key={job.id} value={job.id}>
      {job.name}
    </Option>
  ));
  const resetAll = (resetOption) => {
    if (resetOption === "pool") {
      form.setFieldsValue({
        selectedDomain: "",
        selectedJob: "",
      });
      setSelectedDomain("");
      setDomains([]);
      setSelectedJob("");
      setJobs([]);
      setRoleName("");
      setRoleDescription("");
      setSkills([]);
    } else if (resetOption === "domain") {
      form.setFieldsValue({
        selectedJob: "",
      });
      setSelectedJob("");
      setJobs([]);
      setRoleName("");
      setRoleDescription("");
      setSkills([]);
    } else if (resetOption === "job") {
      setRoleName("");
      setRoleDescription("");
      setSkills([]);
    } else {
      // do nothing
    }
    form.setFieldsValue({
      roleName: "",
      roleDescription: "",
      skills: [],
    });
  };

  const loadDomainExpertTalentPools = async () => {
    try {
      setLoading(true);
      setLoadingFor("Getting Talent Pools...");
      const response = await fetch(
        `${BASE_URL}/api/domain-expert-talent-pools/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${user?.token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setTalentPools(data.talent_pools);
        } else {
          message.error(data.error);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  const loadJobs = async (domain) => {
    resetAll("job");
    try {
      setLoading(true);
      setLoadingFor("Getting Job Roles...");
      const response = await fetch(`${BASE_URL}/api/get-sector-roles/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
        body: JSON.stringify({ sector: domain }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setJobs(data.data);
        } else {
          message.error(data.error);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  useEffect(() => {
    loadDomainExpertTalentPools();
  }, []);

  const handleTalentPoolChange = async (pool_id) => {
    resetAll("pool");
    setSelectedTalentPool(pool_id);

    if (pool_id) {
      try {
        setLoading(true);
        setLoadingFor("Getting Domains...");
        const response = await fetch(
          `${BASE_URL}/api/domain-expert-talent-pool-sectors/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${user?.token}`,
            },
            body: JSON.stringify({ pool_id }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setDomains(data.domains);
          } else {
            message.error(data.error);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setLoadingFor("");
      }
    }
  };

  const handleDomainChange = async (domain) => {
    resetAll("domain");
    setSelectedDomain(domain);

    if (domain) {
      loadJobs(domain);
    }
  };

  const handleJobChange = (job) => {
    resetAll("job");
    setSelectedJob(job);
    if (job) {
      GetSkillsOfTargetRoles(job);
    }
  };

  const GetSkillsOfTargetRoles = async (job) => {
    try {
      setLoading(true);
      setLoadingFor("Getting Skills...");
      const response = await fetch(`${BASE_URL}/api/get-role-skills/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
        body: JSON.stringify({ company: current_company?.id, job: job }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setRoleName(data.role.name);
          setRoleDescription(data.role.description);
          setSkills(data.skills);
          form.setFieldsValue({
            roleName: data.role.name,
            roleDescription: data.role.description,
          });
        } else {
          console.error("Failed to retrieve skills:", data.message);
        }
      }
    } catch (error) {
      console.error("Error fetching skills:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  // Handle "Select All" checkbox change
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setIsAllChecked(checked);
    setSkills((prevSkills) =>
      prevSkills.map((skill) => ({ ...skill, status: checked }))
    );
  };

  const handleStatusChange = (checked, id) => {
    setSkills((prevSkills) =>
      prevSkills.map((skill) =>
        skill.id === id ? { ...skill, status: checked } : skill
      )
    );
    // Update "Select All" checkbox based on individual status changes
    const allChecked = skills.every((skill) => skill.status === true);
    setIsAllChecked(allChecked);
  };

  const handleProficiencyChange = (value, skillId) => {
    setSkills((prevSkills) =>
      prevSkills.map((skill) =>
        skill.id === skillId ? { ...skill, proficiency: value } : skill
      )
    );
  };

  const handleSkillTypeChange = (value, skillId) => {
    setSkills((prevSkills) =>
      prevSkills.map((skill) =>
        skill.id === skillId ? { ...skill, type: value } : skill
      )
    );
  };

  const handleSkillNameChange = (value, skillId) => {
    if (!value.trim()) {
      message.error("Skill Name must not be empty");
      return;
    }
    setSkills((prevSkills) =>
      prevSkills.map((skill) =>
        skill.id === skillId ? { ...skill, name: value } : skill
      )
    );
  };

  const handleSkillDescriptionChange = (value, skillId) => {
    if (!value.trim()) {
      message.error("Skill Description must not be empty");
      return;
    }
    setSkills((prevSkills) =>
      prevSkills.map((skill) =>
        skill.id === skillId ? { ...skill, description: value } : skill
      )
    );
  };
  const columns = [
    {
      title: (
        <Checkbox checked={isAllChecked} onChange={handleSelectAllChange}>
          Select All
        </Checkbox>
      ),
      key: "status",
      render: (text, record) => (
        <Switch
          checked={record.status}
          onChange={(checked) => handleStatusChange(checked, record.id)}
        />
      ),
    },
    {
      title: "Skill Name",
      key: "name",
      render: (text, record) => (
        <Input
          value={record.name}
          onChange={(e) => handleSkillNameChange(e.target.value, record.id)}
        />
      ),
    },
    {
      title: "Skill Type",
      key: "type",
      render: (text, record) => (
        <Select
          value={record.type}
          onChange={(value) => handleSkillTypeChange(value, record.id)}
          style={{ width: "100%" }}
        >
          <Option value="tsc">Technical Skill</Option>
          <Option value="ccs">Core Skill</Option>
          <Option value="ssc">Soft Skill</Option>
        </Select>
      ),
    },
    {
      title: "Proficiency",
      key: "proficiency",
      render: (text, record) => {
        const proficiencyLabels = {
          1: "Basic",
          2: "Intermediate",
          3: "Advanced",
        };

        if (record.type === "ccs" || record.type === "ssc") {
          return (
            <InputNumber
              min={1}
              max={3}
              value={record.proficiency}
              onChange={(value) => handleProficiencyChange(value, record.id)}
              formatter={(value) => proficiencyLabels[value]}
              parser={(value) => {
                if (value === "Basic") return 1;
                if (value === "Intermediate") return 2;
                if (value === "Advanced") return 3;
                return value;
              }}
            />
          );
        } else {
          return (
            <InputNumber
              min={1}
              max={6}
              value={record.proficiency}
              onChange={(value) => handleProficiencyChange(value, record.id)}
            />
          );
        }
      },
    },
    {
      title: "Description",
      key: "description",
      render: (text, record) => (
        <TextArea
          value={record.description}
          onChange={(e) =>
            handleSkillDescriptionChange(e.target.value, record.id)
          }
          placeholder="Enter Skill Description"
          rows={3}
        />
      ),
    },
  ];

  const proficiencyLabels = {
    1: "Basic",
    2: "Intermediate",
    3: "Advanced",
  };

  const onFinish = (values) => {
    // Validate all skill names and descriptions before submitting
    for (const skill of skills) {
      if (!skill.name.trim()) {
        message.error("Please fill all Skill Names before submitting.");
        return;
      }
      if (!skill.description.trim()) {
        message.error("Please fill all Skill Descriptions before submitting.");
        return;
      }
    }
    const formData = {
      ...values, // Keep existing form values like roleName and roleDescription
      company: current_company?.id,
      skills, // Add the skills array to the form data
    };
    console.log("Form Values:", formData);
    // Add the necessary code to handle form submission, including roleName, roleDescription, and skills.
    saveMatrix(formData);
  };

  const saveMatrix = async (formdata) => {
    try {
      setLoading(true);
      setLoadingFor("Saving Matrix");
      const response = await fetch(`${BASE_URL}/api/save-role-skill-matrix/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`,
        },
        body: JSON.stringify(formdata),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          message.success(data.message);
        } else {
          message.error(data.error);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setLoadingFor("");
      GetSkillsOfTargetRoles(selectedJob);
    }
  };

  return (
    <>
      {loading ? <Loader text={loadingFor} /> : ""}
      <div className="update-skill-map mb-4">
        <div class="headingbar d-flex justify-content-between">
          <div class="title">
            <h2>Update Skill Map</h2>
          </div>
        </div>
        <Form
          form={form}
          layout="vertical"
          name="update-skill-map-form"
          onFinish={onFinish}
          style={{ marginTop: "24px" }}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Talent Pool"
                name="selectedTalentPool"
                rules={[
                  { required: true, message: "Please select Talent Pool" },
                ]}
              >
                <Select
                  className="custom-select"
                  placeholder="Select talent pool"
                  value={selectedTalentPool || null}
                  onChange={handleTalentPoolChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {talent_pool_options}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Domain"
                name="selectedDomain"
                rules={[{ required: true, message: "Please Select Domain" }]}
              >
                <Select
                  className="custom-select"
                  placeholder="Select domain"
                  value={selectedDomain || null}
                  onChange={handleDomainChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={!domains.length}
                >
                  {domain_options}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Job"
                name="selectedJob"
                rules={[{ required: true, message: "Please Select Job" }]}
              >
                <Select
                  className="custom-select"
                  placeholder="Select job"
                  value={selectedJob || null}
                  onChange={handleJobChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={!jobs.length}
                >
                  {job_options}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <hr />

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="custom-label"
                label="Role Name"
                name="roleName"
                rules={[{ required: true, message: "Please input role name!" }]}
              >
                <Input
                  value={roleName}
                  readOnly={true}
                  onChange={(e) => setRoleName(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                className="custom-label"
                label="Role Description"
                name="roleDescription"
                rules={[
                  { required: true, message: "Please input role description!" },
                ]}
              >
                <TextArea
                  value={roleDescription}
                  onChange={(e) => setRoleDescription(e.target.value)}
                  placeholder="Enter Role Description"
                  rows={3}
                />
              </Form.Item>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={skills}
            rowKey="id"
            pagination={false}
            style={{ marginBottom: "16px" }}
          />

          {skills.length > 0 && (
            <>
              {/* Add New Skill Button */}
              <Button
                type="primary"
                className="outline-primary"
                onClick={addNewSkill}
                style={{ marginBottom: "20px" }}
              >
                Add New Skill
              </Button>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

export default UpdateSkillMap;
