import LayoutHeading from "./layout-head";
import RenewalIcon from "../../../assets/images/renewal-blank-icon.png";
import { Button, Col, Row } from "react-bootstrap";
import {
  capitalizeFirstLetter
} from "../../utils";
const RenewalStartUp = ({ showModal, assessments, handleSkillTest }) => {
  return (
    <>
      {assessments && assessments.length > 0 && (
        <div className="renewal-list-wrapper">
          <Row>
            {assessments.map((assessment) => (
              <Col
                key={assessment?.id}
                xs={12}
                sm={6}
                md={4}
                className="mb-4 rnl-column"
              >
                <div className="rnl-box">
                  <div className="rnl-box-field">
                    <div className="skill-test">
                      <div className="skill-department">
                        <i className="fa-solid fa-location-crosshairs"></i>
                        <span>
                          {capitalizeFirstLetter(assessment?.name || "")}
                        </span>
                      </div>
                    </div>
                    <ul>
                      {assessment.skills
                        .filter((skill) => skill.status === true) // Ensure this matches your data structure
                        .map((skill) => (
                          <li key={skill.id}>{skill.name}</li> // Added a key prop for uniqueness
                        ))}
                    </ul>
                    {assessment.user_assessment.status === "Completed" ? (
                      <>
                        <ul>
                          <li>
                            <b>Score:</b> {assessment.user_assessment.score} %
                          </li>
                          <li>
                            <b>Proficiency Level:</b>{" "}
                            {assessment.user_assessment.proficiency_level}
                          </li>
                        </ul>
                        <div className="button-box">
                          <Button
                            // disabled={viewLoading}
                            onClick={() =>
                              handleSkillTest(assessment.id, assessment.name)
                            }
                            className="primary-border-btn mt-2"
                          >
                            {"ReTake Skill Test"}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className="button-box">
                        <Button
                          // disabled={viewLoading}
                          onClick={() =>
                            handleSkillTest(assessment.id, assessment.name)
                          }
                          className="primary-border-btn mt-2"
                        >
                          {"Take Skill Test"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      <LayoutHeading pageTitle="Skill Renewal" />
      <div className="subtitle">
        <b>Note:</b> Did you know? Take skill tests without a roadmap! Assess
        your skills and proficiency levels in various job roles.
      </div>
      <div className="blank-layout ">
        <div className="icon-box">
          <img src={RenewalIcon} alt="icon" />
        </div>
        <Button className="primary-btn" onClick={showModal}>
          Explore Skill Tests
        </Button>
      </div>
    </>
  );
};
export default RenewalStartUp;
