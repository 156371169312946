import React, { createContext, useEffect, useContext, useState } from "react";

// Create a context for managing the Google Translate state
const GoogleTranslateContext = createContext();

export const GoogleTranslateProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      const script = document.createElement("script");
      script.id = "google-translate-script";
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      // Define the callback function once
      window.googleTranslateElementInit = () => {
        try {
          new window.google.translate.TranslateElement(
            {
              pageLanguage: "en",
              includedLanguages:
                "ta,te,hi,kn,mr,bn,ar,en,zh-CN,zh-TW,nl,fr,de,id,ja,ko,ms,mn,pl,pt,es,th,vi,sw",
              gaTrack: true,
              gaId: "UA-XXXXXXXX-X",
              autoDisplay: true,
            },
            "google_translate_element"
          );
        } catch (error) {
          console.error("Error initializing Google Translate:", error);
        }
      };

      script.onload = () => {
        setIsLoaded(true); // Set the state to true after the script is loaded
      };

      script.onerror = () => {
        console.error("Error loading Google Translate script.");
      };

      // Cleanup script on unmount if needed
      return () => {
        if (document.getElementById("google-translate-script")) {
          document.getElementById("google-translate-script").remove();
        }
      };
    }
  }, [isLoaded]);

  return (
    <GoogleTranslateContext.Provider value={isLoaded}>
      {children}
    </GoogleTranslateContext.Provider>
  );
};

// Hook to access the translate status
export const useGoogleTranslate = () => {
  return useContext(GoogleTranslateContext);
};
