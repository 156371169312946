import React, { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import Dashboard from "../../assets/images/dashboard/dashboard-icon.png";
import SkillRenewal from "../../assets/images/dashboard/skill-renewal.png";
import Learning from "../../assets/images/dashboard/learning.png";
import TalentHeatMap from "../../assets/images/dashboard/growth.jpg";
import Roadmap from "../../assets/images/dashboard/roadmap.png";
import Goals from "../../assets/images/dashboard/goals.png";
import Activity from "../../assets/images/dashboard/activity.png";
import Mentor from "../../assets/images/dashboard/mentor.png";
import BrowseJourney from "../../assets/images/dashboard/browse-journey.png";
import Support from "../../assets/images/dashboard/support.png";
import Community from "../../assets/images/dashboard/community.png";
import { Scrollbar } from "react-scrollbars-custom";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import AnalyserIcon from "../../assets/images/analyser-icon.png";
import PathWayIcon from "../../assets/images/pathways-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { Button } from "react-bootstrap";
import { storeTestStatus } from "../../store/domainExpert";
import { storeTabStatus } from "../../store/skillRenewal";
import { BASE_URL } from "../../api";
import { support } from "jquery";
import { toMessage } from "../utils";

const Sidebard = ({
  isLoggedIn,
  setIsLoggedIn,
  current_company,
  setCurrent_company,
  profileImage,
  setProfileImage,
  sideBarMenu,
  handleHideSidebarMenu,
  isOrganization,
  userType,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState("");
  const [usertype, setUsertype] = useState("");
  const location = useLocation();
  const pathname = location.pathname;
  const [showredirects, setShowredirects] = useState("Learner");

  const [goalsLink, setGoalsLink] = useState("");
  const [communityLink, setCommunityLink] = useState("");
  const [activityLink, setActivityLink] = useState("");
  const [supportLink, setSupportLink] = useState("");

  const userLo = localStorage.getItem("user");
  const user = userLo ? JSON.parse(localStorage.getItem("user")) : null;

  const [height, setHeight] = useState("100%");
  const topRef = useRef(null);
  const navTitleRef = useRef(null);

  useEffect(() => {
    console.log(userType, pathname, "userType,pathname");
    if (
      userType == "Skill Manager" &&
      (!pathname?.includes("organization") ||
        pathname?.includes("domain-expert"))
    ) {
      toMessage("You do not have permission to access this page.", "warning");
      navigate("/", { replace: true });
    } else if (
      (userType == "Learner" || userType == "Mentor") &&
      (!pathname?.includes("dashboard") ||
        pathname?.includes("organization") ||
        pathname?.includes("domain-expert"))
    ) {
      toMessage("You do not have permission to access this page.", "warning");
      navigate("/", { replace: true });
    } else if (userType == "admin" && !pathname?.includes("admin")) {
      toMessage("You do not have permission to access this page.", "warning");
      navigate("/", { replace: true });
    } else if (
      userType == "Domain Expert" &&
      (!pathname?.includes("domain-expert") ||
        pathname?.includes("organization"))
    ) {
      toMessage("You do not have permission to access this page.", "warning");
      navigate("/", { replace: true });
    }
  }, [pathname, userType]);

  useEffect(() => {
    const updateHeight = () => {
      if (topRef.current && navTitleRef.current) {
        const topHeight = topRef.current.offsetHeight;
        const navTitleHeight = navTitleRef.current.offsetHeight;
        const screenHeight = window.innerHeight;

        setHeight(`calc(100vh - ${topHeight + navTitleHeight + 50}px)`);
      }
    };

    updateHeight(); // Initial height calculation
    window.addEventListener("resize", updateHeight); // Recalculate on window resize

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const jsonData = localStorage.getItem("user");
      if (jsonData) {
        try {
          const userdata = JSON.parse(jsonData);
          console.log("user", userdata);
          setName(userdata.name);
          setProfileImage(userdata.avatar);
          setCompanies(userdata.user_companies);
          setUsertype(userdata.user_type);

          setShowredirects(userdata.user_type);

          let currentCompanyData = localStorage.getItem("current_company");

          setGoalsLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${userdata.type}&gotopage=goals`
          );
          setCommunityLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${userdata.type}&gotopage=community`
          );
          setActivityLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${userdata.type}&gotopage=activity`
          );
          setSupportLink(
            `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${userdata.type}&gotopage=support`
          );

          if (currentCompanyData) {
            let currentCompany = JSON.parse(currentCompanyData);
            setCurrent_company(currentCompany);
          } else {
            setCurrent_company(userdata.logged_company); // Correct way to update state
            localStorage.setItem(
              "current_company",
              JSON.stringify(userdata.logged_company)
            );
          }
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }
      }
    } else {
      navigate("/login");
    }
  }, [isLoggedIn]);

  const handleCompanyChange = (event) => {
    const selectedCompanyId = event.target.value;
    const selectedCompany = companies.find(
      (company) => company.id === selectedCompanyId
    );
    setCurrent_company(selectedCompany); // Correct way to update state
    localStorage.setItem(
      "current_company",
      JSON.stringify({ id: selectedCompanyId, name: selectedCompany?.name })
    );
  };

  if (isOrganization) {
    return (
      <div className="sidebar">
        <div className="sidebar-top">
          <div className="user-profile online">
            <figure>
              <img src={profileImage} alt="" />
            </figure>
            <figcaption>
              <h4 className="mb-0">{name}</h4>
              <div className="usertype align-center">( {usertype} )</div>
            </figcaption>
          </div>
          <div className="change-organisation">
            <h6 className="color-white">
              Organization- <span>{current_company?.name}</span>
            </h6>
            <select
              className="form-select"
              value={current_company.id}
              onChange={handleCompanyChange}
            >
              {companies &&
                companies.map((var_company, index) => (
                  <option key={index} value={var_company.id}>
                    {var_company?.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="sidebar-bottom">
          <div className="nav-title">MAIN NAVIGATION</div>
          <div className="main-navigation">
            <Scrollbar style={{ height: "100vh" }}>
              <ul>
                <li
                  className={
                    pathname === "/organization/dashboard" ||
                    pathname === "/organization/dashboard/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to={"/organization/dashboard/"}>
                    <img src={Dashboard} alt="Dashboard" />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname === "/organization/dashboard/skill-chart" ||
                    pathname === "/organization/dashboard/skill-chart/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/skill-chart">
                    <img src={TalentHeatMap} alt="Talent heatmap" />
                    <span>Talent Heat Map</span>
                  </Link>
                </li>

                <li
                  className={
                    pathname === "/organization/dashboard/visual-map" ||
                    pathname === "/organization/dashboard/visual-map/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/visual-map">
                    <img src={TalentHeatMap} alt="Talent heatmap" />
                    <span>TalentPool Visual Map Generation</span>
                  </Link>
                </li>

                <li
                  className={
                    pathname ===
                      "/organization/dashboard/training-need-analysis" ||
                    pathname ===
                      "/organization/dashboard/training-need-analysis/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/training-need-analysis">
                    <img src={TalentHeatMap} alt="Talent heatmap" />
                    <span>Training Need Analysis</span>
                  </Link>
                </li>

                <li
                  className={
                    pathname ===
                      "/organization/dashboard/company-progress-report" ||
                    pathname ===
                      "/organization/dashboard/company-progress-report/"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/organization/dashboard/company-progress-report">
                    <img src={TalentHeatMap} alt="Talent heatmap" />
                    <span>Company Progress Report</span>
                  </Link>
                </li>
                <li
                  className={pathname === "" || pathname === "" ? "active" : ""}
                >
                  <Link to="/organization/dashboard/search-talent">
                    <img src={TalentHeatMap} alt="Talent heatmap" />
                    <span>Search Talent</span>
                  </Link>
                </li>
              </ul>
            </Scrollbar>
          </div>
        </div>
      </div>
    );
  }

  const handleClick = (type) => {
    if (type == "dashboard") {
      dispatch(storeTestStatus("list"));
    } else if (type == "analyser") {
      localStorage.removeItem("analyserData");
      localStorage.removeItem("analyserDataCopy");
    } else if (type == "renewal") {
      dispatch(storeTabStatus("list"));
    }
  };

  const getDashboardPath = (user) => {
    switch (user?.user_type) {
      case "Domain Expert":
        return "/domain-expert-dashboard";
      case "Admin":
        return "/admin-dashboard";
      default:
        return "/dashboard";
    }
  };

  return (
    <div className={`sidebar ${sideBarMenu ? "mobileSidebar" : "hidesidebar"}`}>
      <div className="sidebar-top" ref={topRef}>
        <Button className="sidebar-close-icon" onClick={handleHideSidebarMenu}>
          <MdClose />
        </Button>
        <div className="user-profile online">
          <figure>
            <img src={profileImage} alt="" />
          </figure>
          <figcaption>
            <h4 className="mb-0">{name}</h4>
            <div className="usertype align-center text-start">
              ( {usertype} )
            </div>
          </figcaption>
        </div>
        <div className="change-organisation">
          <h6 className="color-white mb-0">Organization</h6>
          <h6>
            <span>{current_company?.name}</span>
          </h6>
          <select
            className="form-select"
            value={current_company.id}
            onChange={handleCompanyChange}
          >
            {companies &&
              companies.map((var_company, index) => (
                <option key={index} value={var_company.id}>
                  {var_company?.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="sidebar-bottom">
        <div className="nav-title" ref={navTitleRef}>
          MAIN NAVIGATION
        </div>
        <div className="main-navigation">
          <Scrollbar style={{ height: height }}>
            <ul>
              <li
                className={
                  pathname === "/dashboard" ||
                  pathname === "/dashboard/" ||
                  pathname === "/domain-expert-dashboard" ||
                  pathname === "/domain-expert-dashboard/"
                    ? "active"
                    : ""
                }
              >
                <Link
                  onClick={() => {
                    handleHideSidebarMenu();
                    handleClick("dashboard");
                  }}
                  to={getDashboardPath(user)}
                >
                  <img src={Dashboard} alt="Dashboard Icon" />
                  <span>Dashboard</span>
                </Link>
              </li>
              {user?.user_type === "Admin" && (
                <li>
                  <a
                    rel="noopener"
                    href={`${BASE_URL}/auto-login/?access=${user?.token}&company=${user?.company}&type=${user?.type}`}
                  >
                    <img src={Goals} alt="Old DashBoard" />
                    <span>Old Dashboard</span>
                  </a>
                </li>
              )}
            </ul>
            {pathname && pathname.includes("/domain-expert-dashboard") && (
              <>
                <div className="nav-menu-bar menu-bar-list">
                  {" "}
                  <ul>
                    <li
                      className={
                        pathname ===
                          "/domain-expert-dashboard/certify-skill-proficiency/" ||
                        pathname ===
                          "/domain-expert-dashboard/certify-skill-proficiency"
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        to={
                          "/domain-expert-dashboard/certify-skill-proficiency/?role=no_role"
                        }
                      >
                        <img src={Dashboard} alt="Dashboard" />{" "}
                        <span>Certify Skill Proficiency</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="nav-menu-bar menu-bar-list">
                  {" "}
                  <ul>
                    <li
                      className={
                        pathname ===
                          "/domain-expert-dashboard/update-skillmap" ||
                        pathname === "/domain-expert-dashboard/update-skillmap/"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={"/domain-expert-dashboard/update-skillmap"}>
                        <img src={Dashboard} alt="Dashboard" />{" "}
                        <span>Update Skillmap</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <Accordion defaultActiveKey={["Assessments"]} alwaysOpen>
                  <Accordion.Item eventKey="Assessments">
                    <Accordion.Header>Assesments</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li
                          className={
                            pathname ===
                              "/domain-expert-dashboard/assessment-list" ||
                            pathname ===
                              "/domain-expert-dashboard/assessment-list/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to={"/domain-expert-dashboard/assessment-list"}>
                            <img src={Dashboard} alt="Dashboard" />{" "}
                            <span>Assessment List</span>
                          </Link>
                        </li>
                        <li
                          className={
                            pathname ===
                              "/domain-expert-dashboard/assessment" ||
                            pathname === "/domain-expert-dashboard/assessment/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to={"/domain-expert-dashboard/assessment"}>
                            <img src={Dashboard} alt="Dashboard" />{" "}
                            <span>Create Assessment</span>
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}
            <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
              {pathname && pathname.includes("/dashboard") && (
                <>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Goals</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li
                          className={
                            pathname === "/dashboard/talent-profile" ||
                            pathname === "/dashboard/talent-profile/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="talent-profile">
                            <img src={Goals} alt="Skill Renewal" />
                            <span>My Talent Profile</span>
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/dashboard/talent-profile/create" ||
                            pathname === "/dashboard/talent-profile/create/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="talent-profile/create">
                            <img src={Goals} alt="Talent Profile" />{" "}
                            <span>Update Talent Profile</span>
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Skill Compass</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li
                          className={
                            pathname === "/dashboard/analyser" ||
                            pathname === "/dashboard/analyser/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link
                            to="analyser"
                            onClick={() => {
                              handleClick("analyser");
                              handleHideSidebarMenu();
                            }}
                          >
                            <img src={AnalyserIcon} alt="Skill Renewal" />
                            <span>Analyser</span>
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/dashboard/skill-compass/pathway" ||
                            pathname === "/dashboard/skill-compass/pathway/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link
                            to="skill-compass/pathway"
                            onClick={handleHideSidebarMenu}
                          >
                            <img src={PathWayIcon} alt="Pathway" />{" "}
                            <span>Pathway</span>
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Skill Renewal</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li
                          className={
                            pathname === "/dashboard/renewal" ||
                            pathname === "/dashboard/renewal/" ||
                            pathname === "/dashboard/renewal/skills_graph/" ||
                            pathname === "/dashboard/renewal/skills_graph" ||
                            pathname === "/dashboard/renewal/skills" ||
                            pathname === "/dashboard/renewal/skills/"
                              ? "active"
                              : ""
                          }
                        >
                          <Link
                            to="renewal"
                            onClick={() => {
                              handleClick("renewal");
                              handleHideSidebarMenu();
                            }}
                          >
                            <img src={SkillRenewal} alt="Skill Renewal" />
                            <span>Assessments</span>
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/dashboard/learning" ? "active" : ""
                          }
                        >
                          <Link to="#" onClick={handleHideSidebarMenu}>
                            <img src={Learning} alt="Learning" />{" "}
                            <span>Learning Sprints</span>
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/dashboard/mentor" ? "active" : ""
                          }
                        >
                          <Link to="#" onClick={handleHideSidebarMenu}>
                            <img src={Mentor} alt="Mentor" />
                            <span>Mentor</span>
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              )}

              {showredirects === "Learner" && (
                <>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Support</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li
                          className={
                            pathname === "/dashboard/learning" ? "active" : ""
                          }
                        >
                          <a
                            rel="noopener"
                            href={communityLink}
                            onClick={handleHideSidebarMenu}
                          >
                            <img src={Community} alt="Community" />{" "}
                            <span>Community</span>
                          </a>
                        </li>
                        <li
                          className={
                            pathname === "/dashboard/activity" ? "active" : ""
                          }
                        >
                          <a
                            rel="noopener"
                            href={activityLink}
                            onClick={handleHideSidebarMenu}
                          >
                            <img src={Activity} alt="Activity" />
                            <span>Activity</span>
                          </a>
                        </li>
                        <li
                          className={
                            pathname === "/dashboard/Support" ? "active" : ""
                          }
                        >
                          <a
                            rel="noopener"
                            href={supportLink}
                            onClick={handleHideSidebarMenu}
                          >
                            <img src={Support} alt="Support" />
                            <span>Support</span>
                          </a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="search-jobs">
                    <Accordion.Header>Search Job</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li
                          className={
                            pathname === "/dashboard/learning" ? "active" : ""
                          }
                        >
                          <Link
                            rel="noopener"
                            to={`/dashboard/job-search`}
                            onClick={handleHideSidebarMenu}
                          >
                            <img src={Community} alt="Community" />{" "}
                            <span>Job Search</span>
                          </Link>
                        </li>                      
                        
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              )}
            </Accordion>
          </Scrollbar>
        </div>
      </div>
    </div>
  );
};

export default Sidebard;
