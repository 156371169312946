import React, { Suspense, useState, useEffect } from "react";
//import './App.css';
import "./assets/css/style.css";

import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardRoutes from "./dashboard/component/layout";
import { useSelector, useDispatch } from "react-redux";
import { storeLoginStatus } from "./store/slice";
import RenewalSkills from "./dashboard/layouts/renewal";
import DashboardMentors from "./dashboard/layouts/mentor";
import RenewalAllSkills from "./dashboard/layouts/renewalSkills";
import RenewalSkillsGraph from "./dashboard/layouts/renewalSkillTest";
import Loader from "./components/loader";
import { callGetApi, callGetDomains } from "./services/roadmap_api";
import { storeAllDomains, storeSelectedDomain, storeUserDomains } from "./store/domainExpert";
import { toMessage } from "./dashboard/utils";
import PrivateRoute from "./dashboard/component/hoc/privateRoute";
import UpdateSkillMap from "./dashboard/layouts/updateSkillMap";
import AssessmentCreate from "./dashboard/layouts/assessment-create";
import AssessmentList from "./dashboard/layouts/assessment-list";
import AssessmentAssignTalentPool from "./dashboard/layouts/assessment-assign-talentpool";
import AssessmentSampleQuestions from "./dashboard/layouts/assessment-sample-questions";
import SkillsPathway from "./dashboard/layouts/pathway";
import TalentList from "./dashboard/layouts/TalentList";
import TalentPool from "./dashboard/layouts/talent-pool"
import TalentProfile from "./dashboard/layouts/talent-profile";
import AssessmentSkillTest from "./dashboard/layouts/assessment-skill-test";
import LearnerJobSearch from "./dashboard/layouts/learner-job-search";

const Home = React.lazy(() => import("./pages/home"));
const NotFound = React.lazy(() => import("./pages/not-found"));
const Mentor = React.lazy(() => import("./pages/mentor"));
const MentorDetail = React.lazy(() => import("./pages/mentor-details"));
const Programs = React.lazy(() => import("./pages/programs/program"));
const ProgramDetail = React.lazy(() =>
  import("./pages/programs/program-detail")
);
const AboutUs = React.lazy(() => import("./pages/about"));
const ContactUs = React.lazy(() => import("./pages/contact"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacy-policy"));
const TermsConditions = React.lazy(() => import("./pages/terms-condition"));
const Resources = React.lazy(() => import("./pages/resources/resource"));
const CustomePage = React.lazy(() => import("./pages/custom-page"));
const BlogDetail = React.lazy(() => import("./pages/blog"));
const BlogList = React.lazy(() => import("./pages/bloglist"));
const SkillAnalyser = React.lazy(() => import("./components/SkillAnalyser"));
const Login = React.lazy(() => import("./pages/login"));
const Register = React.lazy(() => import("./pages/register"));
const ForgetPassword = React.lazy(() => import("./pages/forget-password"));
const OTPLogin = React.lazy(() => import("./pages/otp-login"));
const VerifyEmail = React.lazy(() => import("./pages/verfiy-email"));
const Logout = React.lazy(() => import("./pages/logout"));
const DashboardAnalyser = React.lazy(() =>
  import("./dashboard/layouts/analyser")
);

const SkillChart = React.lazy(() => import("./dashboard/layouts/skill-chart"));
const VisualMap = React.lazy(() => import("./dashboard/layouts/visual-map"));
const TariningNeedAnalysis = React.lazy(() => import("./dashboard/layouts/training-need-analysis"));
const CompanyProgressReport = React.lazy(() => import("./dashboard/layouts/company-progress-report"));
const SearchTalent = React.lazy(() => import("./dashboard/component/search-talent/search-talent"))

const TalentPoolList = React.lazy(() => import("./dashboard/adminLayouts/talent-pool-list"));

// Dashboard Routing
const Dashboard = React.lazy(() => import("./dashboard/pages/dashboard"));
const UserDashboard = React.lazy(() =>
  import("./dashboard/pages/user-dashboard")
);
const SkillRenewal = React.lazy(() =>
  import("./dashboard/pages/skill-renewal")
);
const Profile = React.lazy(() => import("./dashboard/pages/profile"));
const Leaderboard = React.lazy(() => import("./dashboard/pages/Leaderboard "));
const Certificates = React.lazy(() => import("./dashboard/pages/Certificates"));
const ChangePassword = React.lazy(() =>
  import("./dashboard/pages/ChangePassword")
);

function App() {
  const dispatch = useDispatch();
  const [User, setUser] = useState(null);

  let userLogin = localStorage.getItem("user");

  useEffect(() => {
    const userdata = userLogin ? JSON.parse(userLogin) : null;
    if (userdata) {
      setUser(userdata);
      setProfileImage(userdata.avatar);
    }else{
      setUser(null)
    }
    dispatch(storeLoginStatus(userLogin ? true : false));
    if (userdata?.type == "12") {
      getDomainType(userdata?.id);
    }
    return()=>{
      setUser(null)
    }
  }, [userLogin]);

  const { isLoggedIn, gJobRole } = useSelector((state) => state?.counterSlice);

  const setIsLoggedIn = (state) => {
    dispatch(storeLoginStatus(state));
  };

  const [current_company, setCurrent_company] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const getDomainType = async (id) => {
     // let idWithoutDash = id.replace(/-/g, ''); 
    // const res = await callGetApi(`/api/domain-expert/domain_name/?user_id=${idWithoutDash || "6a17ef31a8a74ee6b1a087acca31b599"}`);
   
    const res = await callGetDomains(id);
    console.log(res, "res");
    if (res && res?.success) {
      let domains=res?.domain_list?.map((it)=>({label:it?.name, value:it?.name}));
      dispatch(storeUserDomains(domains))
      let domain = domains[0];
      dispatch(storeSelectedDomain(domain));
    } else {
      toMessage(
        "Currently, no domain is assigned to you. PLease contact the admin.' ",
        "warning"
      );
    }
  };

  if(isLoggedIn && User==null){
    return <Loader/>
  }
  
  console.log(User, "User userdata");
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          element={
            <Suspense>
              <Layout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            </Suspense>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="mentors" element={<Mentor />} />
          <Route path="mentor-detail" element={<MentorDetail />} />
          <Route path="programs" element={<Programs />} />
          <Route
            path="programs/:uuid"
            element={
              <ProgramDetail
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route path="about" element={<AboutUs />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-services" element={<TermsConditions />} />
          <Route path="resources" element={<Resources />} />
          <Route path="/page/:slug" element={<CustomePage />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/blog" element={<BlogList />} />

          <Route path="/skill-analyser" element={<SkillAnalyser />} />
          <Route
            path="/login"
            element={
              <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />
          <Route
            path="/login/:uidb64"
            element={
              <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />
          <Route
            path="/logout"
            element={
              <Logout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />
          
          <Route
            path="/register"
            element={
              <Register isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />

          <Route
            path="/register/:uidb64/:token"
            element={
              <Register isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />
          <Route
            path="/register/:uidb64"
            element={
              <Register isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route
            path="/otp-login"
            element={
              <OTPLogin isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />
          <Route
            path="/otp-login/:uidb64"
            element={
              <OTPLogin isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            }
          />
          <Route
            path="/emailverify/:uidb64/:token"
            element={<VerifyEmail   isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}  />}
          />
        </Route>

        {/* Dashboard Routing */}

        <Route
          path="/domain-expert-dashboard/"
          element={
              <Suspense fallback={<Loader />}>
                <DashboardRoutes
                  current_company={current_company}
                  setCurrent_company={setCurrent_company}
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  userType={User?.user_type}
                />
              </Suspense>
          }
        >
          <Route path="" element={<DashboardMentors />} />
          <Route path="certify-skill-proficiency" element={<DashboardMentors />} />
          <Route path="update-skillmap" element={<UpdateSkillMap />} />
          <Route path="assessment" element={<AssessmentCreate />} />
          <Route path="assessment/:id" element={<AssessmentCreate />} />
          <Route path="assessment-list" element={<AssessmentList />} />
          <Route path="assessment/assign/:id" element={<AssessmentAssignTalentPool />} />
          <Route path="assessment/sample-questions/:id" element={<AssessmentSampleQuestions />} />
        </Route>

        <Route
          path="/dashboard/"
          element={
              <Suspense fallback={<Loader />}>
                <DashboardRoutes
                  current_company={current_company}
                  setCurrent_company={setCurrent_company}
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  userType={User?.user_type}
                />
              </Suspense>
          }
        >
          <Route path="" element={<UserDashboard />} />
          <Route path="talent-profile" element={<TalentProfile />} />
          <Route path="talent-profile/create" element={<TalentList />} />
          <Route path="analyser" element={<DashboardAnalyser />} />
          <Route path="skill-compass/pathway" element={<SkillsPathway />} />
          <Route path="renewal" element={<RenewalSkills />} />
          <Route path="renewal/skills" element={<RenewalAllSkills />} />
          <Route path="renewal/skills_graph" element={<RenewalSkillsGraph />} />
          <Route path="skill-test" element={<AssessmentSkillTest />} />
          <Route path="job-search" element={<LearnerJobSearch />} />
          <Route
            path="profile"
            element={
              <Profile
                current_company={current_company}
                setCurrent_company={setCurrent_company}
                profileImage={profileImage}
                setProfileImage={setProfileImage}
              />
            }
          />
          <Route
            path="leaderboard"
            element={
              <Leaderboard
                current_company={current_company}
                setCurrent_company={setCurrent_company}
                profileImage={profileImage}
                setProfileImage={setProfileImage}
              />
            }
          />
          <Route
            path="certificates"
            element={
              <Certificates
                current_company={current_company}
                setCurrent_company={setCurrent_company}
                profileImage={profileImage}
                setProfileImage={setProfileImage}
              />
            }
          />
          <Route
            path="change-password"
            element={
              <ChangePassword
                current_company={current_company}
                setCurrent_company={setCurrent_company}
                profileImage={profileImage}
                setProfileImage={setProfileImage}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
        </Route>
        {/* Dashboard Routing */}

        <Route
          path="/organization/dashboard/"
          element={
              <Suspense fallback={<Loader />}>
                <DashboardRoutes 
                  current_company={current_company}
                  setCurrent_company={setCurrent_company}
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  userType={User?.user_type}
                  
                />
              </Suspense>
          }
        >
          <Route path="" element={<SkillChart hidden = {false} />} />
          <Route path="skill-chart" element={<SkillChart hidden={true} />} />
          <Route path="visual-map" element={<VisualMap hidden={true} />} />
          <Route path="training-need-analysis" element={<TariningNeedAnalysis hidden={true} />} />
          <Route path="company-progress-report" element={<CompanyProgressReport hidden={true} />} />
          <Route path="search-talent" element={<SearchTalent />} />
        </Route>


        <Route
          path="/admin-dashboard/"
          element={
              <Suspense fallback={<Loader />}>
                <DashboardRoutes 
                  current_company={current_company}
                  setCurrent_company={setCurrent_company}
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  userType={User?.user_type}
                  
                />
              </Suspense>
          }
        >
          <Route path="" element={<TalentPoolList />} />
          <Route path="talent-pool/:id" element={<TalentPool />} />
        </Route>

      </Routes>
    </Router>
  );
}

export default App;
